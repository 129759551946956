<template>
    <div>
        <Close/>
        <div v-if="selectedSensor && selectedSensor.type === 'buoy'" >
            <Buoy :selectedSensor="selectedSensor"/>
        </div>
        <div v-else-if="selectedSensor && selectedSensor.type === 'camera'">
            <Camera :selectedSensor="selectedSensor"/>
        </div>
        <div v-else>
            <div class="sub">
                To view sensor details, please select a sensor from the map
                <b-icon icon="map" size="is-small"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
import Buoy from '@/components/Buoy.vue'
import Camera from '@/components/Camera.vue'
import Close from '@/components/utils/Close.vue'

export default {
    name: 'Sensors',
    props: ['selectedSensor'],
    components: {
        Buoy,
        Camera,
        Close,
    },
}
</script>

<style scoped>
</style>