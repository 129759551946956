import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Layers from '@/components/Layers.vue'
import Sensors from '@/components/Sensors.vue'
import Analysis from '@/components/Analysis.vue'
import OutplantSites from '@/components/OutplantSites.vue'
import Search from '@/components/Search.vue'
import Nurseries from '@/components/Nurseries.vue'
import Branches from '@/components/Branches.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: Layers,
      },
      {
        path: '/layers',
        name: 'layers',
        component: Layers,
      },
      {
        path: '/branches',
        name: 'branches',
        component: Branches,
      },
      {
        path: '/outplantSites',
        name: 'outplantSites',
        component: OutplantSites,
      },
      {
        path: '/nurseries',
        name: 'nurseries',
        component: Nurseries,
      },
      {
        path: '/sensors',
        name: 'sensors',
        component: Sensors,
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: Analysis,
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
	mode: 'abstract',
  	routes
})

export default router
