<template>
    <div class="sidebar-wrapper">
        <Close/>
        <div class="level is-mobile title">
            <div class="level-left">
                <div class="level-item">
                    <div>
                        SEARCH <br>
                         <p class="sub-title">FIND A LOCATION</p>
                    </div>
                </div>
            </div>
        </div>
        <!-------------------------- End title ------------------------>
        <div id="geocoder">
        </div>
    </div>
</template>

<script>
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from "mapbox-gl";
import Close from '@/components/utils/Close.vue'

export default {
    name: 'Search',
    props: ['map'],
    components: {
        Close
    },
    data() {
        return {
            token: 'c1c866d05b045ce586fa77e6fc9dc2',
        }
    },
    mounted() {
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            localGeocoder: this.forwardGeocoder,
            placeholder: 'Search for reefs or sites',
            mapboxgl: mapboxgl
        });
        document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
        this.map.easeTo({
            padding: { left: 300 },
            duration: 600
        });
    },
    methods: {
        forwardGeocoder(query) {
            let layers = ['reef-layer']
            const matchingFeatures = [];
            // TODO: Loop through all map features and check for a match.
            layers.forEach((layer) => {
                // console.log(layer)
                const features = this.map.queryRenderedFeatures({
                    layers: [layer],
                });
                features.forEach(feature => {
                    if (feature.properties.name.toLowerCase().includes(query.toLowerCase())) {
                        matchingFeatures.push({
                            place_name: feature.properties.name,
                            center: feature.geometry.coordinates[0][0],
                        });
                    }
                });
            });
            return matchingFeatures;
        }
    },
}
</script>

<style scoped>

</style>