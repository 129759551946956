<template>
    <div class="reef-wrapper">
        <Close />
        <div v-if="selectedNursery">
            <div class="level is-mobile title">
                <div class="level-left">
                    <div class="level-item">
                        <div>
                            {{ selectedNursery.name.toUpperCase() }} <br>
                            <p class="sub-title">CORAL NURSERY</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLoading" class="loading-container">
                <div class="loading-bar">
                    <div class="loading-text">Loading data...</div>
                </div>
            </div>
            <div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub">
                            <div style="float: left;">Corals Growing</div><br>
                            <div class="sub-number">{{ getFragmentCount }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub">
                            <div style="float: left;">Species</div><br>
                            <div class="sub-number">{{ getSpeciesCount }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub">
                            <div style="float: left;">Genera</div><br>
                            <div class="sub-number">{{ getGeneraCount }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub">
                            <div style="float: left;">Mother Colonies</div><br>
                            <div class="sub-number">{{ getMotherColonyCount }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sub">
                <div :class="{ 'no-data': !hasHealthData }">
                    <div class="clearfix">
                        <div style="float: left; width: 50%;">
                            <canvas class="chart-wrapper" id="doughnut-chart"></canvas>
                        </div>
                        <div style="float: left; width: 50%;">
                            <div style="padding: 20px">
                                <div class="legend-large">
                                    <div style="float: left; background-color: #27BDF4" class="square-large"></div>
                                    <div style="float: left;">Survival</div>
                                    <div style="clear: both;"></div>
                                </div>
                                <div class="legend-large" style="margin-top: 5px;">
                                    <div style="float: left; background-color: white" class="square-large"></div>
                                    <div style="float: left;">Bleaching</div>
                                    <div style="clear: both;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="clearfix">
                        <div style="float: left; width: 50%;">
                            <div class="sub">
                                <div style="float: left;">Survival %
                                    <b-tooltip label="Average percentage of corals that are alive" position="is-bottom">
                                        <b-icon icon="information-outline" size="is-small"
                                            style="margin-left: 5px;"></b-icon>
                                    </b-tooltip>
                                </div><br>
                                <div class="sub-number">{{ getAlivePercentage }}</div>
                                <div style="clear: both;"></div>
                            </div>
                        </div>
                        <div style="float: left; width: 50%;">
                            <div class="sub">
                                <div style="float: left;">Bleaching %
                                    <b-tooltip label="Average bleaching across all corals" position="is-bottom">
                                        <b-icon icon="information-outline" size="is-small"
                                            style="margin-left: 5px;"></b-icon>
                                    </b-tooltip>
                                </div><br>
                                <div class="sub-number">{{ getBleachingPercentage }}</div>
                                <div style="clear: both;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!hasHealthData" class="no-data-overlay">
                    Data not available
                </div>
            </div>

            <div class="sub genus-card">
                <div class="chart-title">DIVERSITY</div>
                <div class="chart-container">
                    <canvas class="chart-wrapper" id="genus-chart"></canvas>
                    <div class="custom-legend">
                        <div v-for="(genus, index) in topGenera" :key="genus.name" class="legend-item">
                            <span class="legend-color"
                                :style="{ backgroundColor: colors[index % colors.length] }"></span>
                            {{ genus.name }}
                        </div>
                        <div class="legend-item" v-if="otherFraction > 0">
                            <span class="legend-color" :style="{ backgroundColor: colors[colors.length - 1] }"></span>
                            Other
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="sub">
                <div class="chart-title">CORAL GROWTH OVER TIME</div>
                <canvas class="chart-wrapper" id="growth-chart"></canvas>
            </div> -->

            <div class="sub">
                <div class="chart-title">BLEACHING</div>
                <div class="chart-wrapper">
                    <div :class="{ 'no-data': !hasBleachingData }">
                        <canvas id="bleaching-chart"></canvas>
                    </div>
                    <div v-if="!hasBleachingData" class="no-data-overlay">
                        Data not available
                    </div>
                </div>
            </div>

            <div class="sub">
                <div class="chart-title">SURVIVAL</div>
                <div class="chart-wrapper">
                    <div :class="{ 'no-data': !hasSurvivalData }">
                        <canvas id="survival-chart"></canvas>
                    </div>
                    <div v-if="!hasSurvivalData" class="no-data-overlay">
                        Data not available
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sub">
                To view coral nursery details, please select a reef from the map
                <b-icon icon="map" size="is-small"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
import { eventBus } from '@/events.js'
import Close from '@/components/utils/Close.vue'

export default {
    name: 'Nurseries',
    props: ['selectedNursery'],
    components: {
        Close,
    },
    data() {
        return {
            nurseryData: null,
            doughnutChart: null,
            genusChart: null,
            growthChart: null,
            bleachingChart: null,
            survivalChart: null,
            colors: ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#333333'],
            otherFraction: 0,
            isLoading: false,
        }
    },
    computed: {
        parsedSpeciesSummary() {
            return this.nurseryData && this.nurseryData.nursery && this.nurseryData.nursery.species_summary
                ? this.nurseryData.nursery.species_summary
                : [];
        },
        parsedMonitoring() {
            return this.nurseryData && this.nurseryData.nursery && this.nurseryData.nursery.monitoring
                ? this.nurseryData.nursery.monitoring
                : [];
        },
        parsedStructures() {
            return this.nurseryData && this.nurseryData.nursery && this.nurseryData.nursery.structures
                ? this.nurseryData.nursery.structures
                : []
        },
        parsedSummary() {
            return this.nurseryData && this.nurseryData.nursery && this.nurseryData.nursery.summary
                ? this.nurseryData.nursery.summary
                : {};
        },
        getFragmentCount() {
            return (this.parsedSummary.corals || 0).toLocaleString();
        },
        getSpeciesCount() {
            return this.parsedSummary.species || 0;
        },
        getGeneraCount() {
            return this.parsedSummary.genera || 0;
        },
        getMotherColonyCount() {
            return this.parsedSummary.colonies || 0;
        },
        getAlivePercentage() {
            const aliveDecimal = this.parsedSummary.alive || 0;
            return (aliveDecimal * 100).toFixed(1);
        },
        getBleachingPercentage() {
            const bleachLevel = this.parsedSummary.bleach || 1;
            // Convert 1-6 scale to 0-100%
            return (((bleachLevel - 1) / 5) * 100).toFixed(1);
        },
        topGenera() {
            const genusCount = {};
            this.parsedSpeciesSummary.forEach(species => {
                const genus = species.taxon.split(' ')[0];
                genusCount[genus] = (genusCount[genus] || 0) + species.count;
            });

            const total = Object.values(genusCount).reduce((sum, count) => sum + count, 0);
            const generaArray = Object.entries(genusCount).map(([genus, count]) => ({
                name: genus,
                fraction: count / total
            }));
            generaArray.sort((a, b) => b.fraction - a.fraction);

            const topGenera = generaArray.slice(0, 5);
            const otherGenera = generaArray.slice(5);
            this.otherFraction = otherGenera.reduce((sum, genus) => sum + genus.fraction, 0);

            return topGenera;
        },
        parsedHistory() {
            return this.nurseryData && this.nurseryData.nursery && this.nurseryData.nursery.history
                ? this.nurseryData.nursery.history
                : { alive: {}, bleach: {} };
        },
        hasBleachingData() {
            return Object.keys(this.parsedHistory.bleach || {}).length > 0;
        },
        hasSurvivalData() {
            return Object.keys(this.parsedHistory.alive || {}).length > 0;
        },
        hasHealthData() {
            return this.parsedSummary.alive != null && 
                   this.parsedSummary.bleach != null;
        }
    },
    mounted() {
        if (this.selectedNursery) {
            this.getNurseryData();
        }
    },
    watch: {
        selectedNursery: function (newSelected) {
            this.destroyCharts();
            this.nurseryData = null;
            this.$nextTick(() => {
                this.getNurseryData();
            });
        }
    },
    methods: {
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        },
        async getNurseryData() {
            this.isLoading = true;
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/nursery?org=coral-gardeners&nursery=${this.selectedNursery.id}&pwd=showmethedata`);
                this.nurseryData = await response.json();
                this.initCharts();
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching nursery data:', error);
            }
        },
        initCharts() {
            this.initDoughnutChart();
            this.initGenusChart();
            // this.initGrowthChart();
            this.initBleachingChart();
            this.initSurvivalChart();
        },
        destroyCharts() {
            if (this.doughnutChart) this.doughnutChart.destroy();
            if (this.genusChart) this.genusChart.destroy();
            if (this.growthChart) this.growthChart.destroy();
            if (this.bleachingChart) this.bleachingChart.destroy();
            if (this.survivalChart) this.survivalChart.destroy();
        },
        initDoughnutChart() {
            if (!this.hasHealthData) return;
            
            const ctx = document.getElementById('doughnut-chart').getContext('2d');
            const alivePercentage = parseFloat(this.getAlivePercentage);
            const bleachingPercentage = parseFloat(this.getBleachingPercentage);

            this.doughnutChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [
                        {
                            data: [alivePercentage, 100 - alivePercentage],
                            backgroundColor: ['#27BDF4', '#214F4A'],
                            borderWidth: 0,
                        },
                        {
                            data: [100, 0],
                            backgroundColor: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)'],
                            borderWidth: 0,
                            weight: 0.5
                        },
                        {
                            data: [bleachingPercentage, 100 - bleachingPercentage],
                            backgroundColor: ['white', '#4C4C4C'],
                            borderWidth: 0,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    cutout: '45%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                }
            });
        },
        initGenusChart() {
            const ctx = document.getElementById('genus-chart').getContext('2d');
            const data = [
                ...this.topGenera.map(genus => genus.fraction),
                this.otherFraction
            ];
            const labels = [
                ...this.topGenera.map(genus => genus.name),
                'Other'
            ];

            this.genusChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: this.colors,
                        borderWidth: 0,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    cutoutPercentage: 50,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                const dataset = data.datasets[tooltipItem.datasetIndex];
                                const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                const currentValue = dataset.data[tooltipItem.index];
                                const percentage = ((currentValue / total) * 100).toFixed(1);
                                return `${data.labels[tooltipItem.index]}: ${percentage}%`;
                            }
                        }
                    }
                }
            });
        },
        initGrowthChart() {
            const ctx = document.getElementById('growth-chart').getContext('2d');
            const data = this.processGrowthData();

            this.growthChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: [{
                        label: 'Average Size',
                        data: data.averageSizes.map((size, index) => ({
                            t: new Date(data.labels[index]),
                            y: size
                        })),
                        borderColor: '#27bdf4',
                        backgroundColor: 'rgba(39, 189, 244, 0.1)',
                        fill: true,
                    }]
                },
                options: this.getChartOptions('Growth (cm)')
            });
        },
        initBleachingChart() {
            if (!this.hasBleachingData) return;
            const ctx = document.getElementById('bleaching-chart').getContext('2d');
            const data = this.processBleachingData();

            this.bleachingChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: [{
                        label: 'Average Bleaching',
                        data: data.averageBleaching.map((bleach, index) => ({
                            t: new Date(data.labels[index]),
                            y: bleach
                        })),
                        borderColor: '#27bdf4',
                        backgroundColor: 'rgba(39, 189, 244, 0.1)',
                        fill: true,
                    }]
                },
                options: this.getChartOptions('Bleaching Level (%)')
            });
        },
        initSurvivalChart() {
            if (!this.hasSurvivalData) return;
            const ctx = document.getElementById('survival-chart').getContext('2d');
            const data = this.processSurvivalData();

            this.survivalChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: [{
                        label: 'Survival Rate',
                        data: data.survivalRates.map((rate, index) => ({
                            t: new Date(data.labels[index]),
                            y: rate
                        })),
                        borderColor: '#27bdf4',
                        backgroundColor: 'rgba(39, 189, 244, 0.1)',
                        fill: true,
                    }]
                },
                options: this.getChartOptions('Survival Rate (%)')
            });
        },
        getChartOptions(yAxisLabel) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'month',
                            displayFormats: {
                                month: 'MMM YYYY'
                            }
                        },
                        gridLines: {
                            color: 'rgba(255, 255, 255, 0.1)',
                        },
                        ticks: {
                            fontColor: 'white',
                            maxRotation: 45,
                            autoSkip: true,
                            maxTicksLimit: 12,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'rgba(255, 255, 255, 0.1)',
                        },
                        ticks: {
                            fontColor: 'white',
                        },
                        scaleLabel: {
                            display: true,
                            labelString: yAxisLabel,
                            fontColor: 'white',
                        }
                    }]
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
            };
        },
        processGrowthData() {
            const sortedData = this.parsedMonitoring.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            const aggregatedData = {};

            sortedData.forEach(entry => {
                const date = new Date(entry.Date);
                const key = date.toISOString().split('T')[0];
                if (!aggregatedData[key]) {
                    aggregatedData[key] = { totalSize: 0, count: 0 };
                }
                const size = (entry.length || 0) + (entry.width || 0) + (entry.height || 0);
                if (size > 0) {
                    aggregatedData[key].totalSize += size;
                    aggregatedData[key].count++;
                }
            });

            const labels = Object.keys(aggregatedData);
            const averageSizes = labels.map(key =>
                aggregatedData[key].count > 0 ? aggregatedData[key].totalSize / aggregatedData[key].count : null
            );

            return { labels, averageSizes };
        },
        processBleachingData() {
            const data = this.parsedHistory.bleach || {};
            const sortedEntries = Object.entries(data)
                .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

            const labels = sortedEntries.map(([date]) => date);
            // Convert bleaching values from 1-6 scale to percentage
            const bleachingValues = sortedEntries.map(([, value]) => 
                ((value - 1) / 5 * 100).toFixed(1)
            );

            return { labels, averageBleaching: bleachingValues };
        },
        processSurvivalData() {
            const data = this.parsedHistory.alive || {};
            const sortedEntries = Object.entries(data)
                .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

            const labels = sortedEntries.map(([date]) => date);
            // Convert survival values from decimal to percentage
            const survivalValues = sortedEntries.map(([, value]) => 
                (value * 100).toFixed(1)
            );

            return { labels, survivalRates: survivalValues };
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            return `${month} ${year}`;
        },
    }
}
</script>

<style scoped>
.chart-wrapper {
    position: relative;
    padding: 10px;
    border-radius: 4px;
    max-height: 200px;
}

.reef-wrapper {
    font-family: 'Lato', sans-serif;
}

.level-wrapper {
    font-size: 16px;
    font-family: 'Riposte', sans-serif;
    padding: 10px;
}

.title {
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    font-size: 36px;
    color: white;
}

.sub-title {
    color: var(--primary);
    font-size: 20px;
}

.sub {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #1F1F1F;
    font-size: 14px;
    padding: 8px;
    font-family: 'Riposte', sans-serif;
}

.sub-number {
    font-size: 28px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.sub-unit {
    font-size: 12px;
}

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}

.square-large {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 16px;
}

.square {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 12px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.genus-card {
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 250px;
}

.genus-title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
}

.chart-container {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75%;
}

.chart-container canvas {
    max-width: 50%;
    max-height: 90%;
}

.custom-legend {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 12px;
}

.legend-item {
    display: flex;
    align-items: center;
}

.legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    padding: 12px;
    position: relative
}

.loading-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.loading-bar::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #27bdf4;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -50%;
    }

    50% {
        left: 100%;
    }

    100% {
        left: -50%;
    }
}

.loading-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
    top: -20px;
    z-index: 10;
}

.no-data {
    filter: blur(3px);
    opacity: 0.5;
}

.no-data-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    z-index: 10;
    filter: none !important;
}
</style>
