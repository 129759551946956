import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store.js'

import Buefy from 'buefy'
import './assets/styles/buefy.css'
import './assets/styles/reefmap.css'
import './assets/styles/mapbox.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDByYxdnctmqQdd3VtjBV4NMAPe7O5_f64",
  authDomain: "cg-reefmap.firebaseapp.com",
  projectId: "cg-reefmap",
  storageBucket: "cg-reefmap.appspot.com",
  messagingSenderId: "761959520605",
  appId: "1:761959520605:web:d28c911b8a9d066aec30b4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
export const db = getFirestore(app);

const firebaseConfig_RestorationApp = {
  apiKey: "AIzaSyBc3QUNddqjuuDhu6CHqV6kIq2ALQWaHMk",
  authDomain: "restoration-ios.firebaseapp.com",
  projectId: "restoration-ios",
  storageBucket: "restoration-ios.appspot.com",
  messagingSenderId: "96749053095",
  appId: "1:96749053095:web:1c5163ac423f7e1185e782",
  measurementId: "G-R02354DHWZ"
};

const app_RestorationApp = initializeApp(firebaseConfig_RestorationApp, "RestorationApp");
export const db_RestorationApp = getFirestore(app_RestorationApp)

signInAnonymously(auth);

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
