<template>
    <div class="sidebar-wrapper">
        <Close/>
        <div class="level is-mobile title">
            <div class="level-left">
                <div class="level-item">
                    <div>
                        ANALYTICS <br>
                        <p class="sub-title">PLAN & EXPLORE</p>
                    </div>
                </div>
            </div>
        </div>
        <!-------------------------- End title ------------------------>
        <div class="sub-dark" style="margin: 0px;">
            <div class="calc-title">Calculate area</div>
            <div class="calc">{{  area  }}<span class="calc-unit"> km2</span></div>
            <div class="clearfix">
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="drawPolygon()" icon-left="shape-polygon-plus" type="is-primary">Draw polygon</b-button>
                    </div>
                    </div>
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="trash()" icon-left="trash-can">Reset</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-dark" style="margin: 0px;">
                <div class="calc-title">Calculate distance</div>
            <div class="calc">{{ length }}<span class="calc-unit"> km</span></div>
            <div class="clearfix">
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="drawLine()" icon-left="shape-polygon-plus" type="is-primary">Draw line</b-button>
                    </div>
                    </div>
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="trash()" icon-left="trash-can">Reset</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-dark" style="margin: 0px;">
            <div class="calc-title">Investigate point</div>
            <div class="calc-sub-title"><b-icon icon="layers-outline" size="is-small"></b-icon>
                 Turn on Bathymetry, Benthic, and Geomorphic Layers, and zoom in to view details</div>
            <!-- <div class="clearfix">
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="startInvestigate()" icon-left="map-search-outline" type="is-primary">Inspect</b-button>
                    </div>
                    </div>
                <div style="float: left; width: 50%;">
                    <div class="btn-wrapper">
                        <b-button class="analysis-btn" @click="stopInvestigate()" icon-left="stop">Stop</b-button>
                    </div>
                </div>
            </div> -->
            <div class="sub">
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Reef Name</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div style="text-transform: capitalize;">{{ inspect['reef'] }}</div>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Benthic Class</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div>{{ inspect['benthic'] }}</div>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Geomorphic Class</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div>{{ inspect['geomorphic'] }}</div>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Depth</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div>{{ inspect['depth'] }} m</div>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Elevation</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div>{{ inspect['elevation'] }} m</div>
                        </div>
                    </div>
                </div>
                <div class="level is-mobile" style="margin: 5px;">
                    <div class="level-left">
                        <div class="level-item">
                            <div>Location</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div>{{ inspect['lon'] }}, {{ inspect['lat'] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { eventBus } from '@/events.js'
import Close from '@/components/utils/Close.vue'

export default {
    name: 'Analysis',
    props: ['map'],
    components: {
        Close,
    },
    data() {
        return {
            draw: null,
            area: '-',
            length: '-',
            inspect: { 
                reef: '', 
                benthic: '', 
                geomorphic: '', 
                depth: '', 
                elevation: '',
                lon: '',
                lat: '',
            },
        }
    },
    mounted() {
        this.initDraw()
        this.initInvestigate();
    },
    beforeRouteLeave(to, from, next) {
        this.map.removeControl(this.draw);
        this.map.off('draw.create', this.updateArea);
        this.map.off('draw.delete', this.updateArea);
        this.map.off('draw.update', this.updateArea);
        this.draw = null;
        next()
    },
    methods: {
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        },
        initDraw() {
            this.draw = new MapboxDraw({
                displayControlsDefault: false,
            });
            this.map.addControl(this.draw);
            this.map.on('draw.create', this.updateArea);
            this.map.on('draw.delete', this.updateArea);
            this.map.on('draw.update', this.updateArea);
        },
        drawPolygon() {
            this.draw.changeMode('draw_polygon');
            this.map.getCanvas().style.cursor = 'crosshair';
        },
        drawLine() {
            this.draw.changeMode('draw_line_string');
            this.map.getCanvas().style.cursor = 'crosshair';
        },
        updateArea() {
            this.map.getCanvas().style.cursor = 'pointer';
            const data = this.draw.getAll();
            if (data.features.length > 0) {
                if (data.features[0].geometry.type === 'Polygon') {
                    this.area = (turf.area(data) / 1000000).toFixed(2); // in square km
                } else if (data.features[0].geometry.type === 'LineString') {
                    this.length = turf.length(data).toFixed(2); // in km
                }
            } 
        },
        trash() {
            this.area = '-';
            this.length = '-';
            let selectedFeatures = this.draw.getSelected();
            if (selectedFeatures.features.length) {
                this.draw.delete(selectedFeatures.features[0].id);
            }
        },
        initInvestigate() {
            this.map.on('mousemove', (e) => {
                this.inspect['lon'] = e.lngLat.lng.toFixed(4);
                this.inspect['lat'] = e.lngLat.lat.toFixed(4);
                this.inspect['elevation'] = Math.floor(this.map.queryTerrainElevation(e.lngLat, { exaggerated: false }));
                const features = this.map.queryRenderedFeatures(e.point);
                features.forEach((feature) => {
                    if (feature.layer.id === 'reef-layer') {
                        this.inspect['reef'] = feature.properties.name;
                    } else if (feature.layer.id === 'benthic') {
                        this.inspect['benthic'] = feature.properties.class;
                    } else if (feature.layer.id === 'geomorphic') {
                        this.inspect['geomorphic'] = feature.properties.class;
                    } else if (feature.layer.id === 'bathymetry') {
                        this.inspect['depth'] = (feature.properties['_mean'] / 100).toFixed(1);
                    }
                });
            });
        },
        startInvestigate() {
            this.map.getCanvas().style.cursor = 'help';
        },
        stopInvestigate() {
            this.map.getCanvas().style.cursor = 'pointer';
            this.inspect = { reef: '', benthic: '', geomorphic: '', depth: '', elevation: '' };
        }
    },
}
</script>

<style scoped>
.draw-cursor {
    cursor: crosshair !important;
}
.analysis-btn {
    width: 100%;
}
.btn-wrapper {
    margin: 10px;
}
.calc-title {
    margin-left: 10px;
    font-size: 16px;
}
.calc-sub-title {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 14px;
}
.calc {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}
.calc-unit {
    font-size: 12px;
    font-weight: normal;
}

</style>