<template>
    <div class="reef-wrapper">
        <Close />
        <div v-if="selectedOutplantSite">
            <div class="level is-mobile title">
                <div class="level-left">
                    <div class="level-item">
                        <div>
                            {{ selectedOutplantSite.name.toUpperCase() }} <br>
                            <p class="sub-title">OUTPLANT SITE</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isLoading" class="loading-container">
                <div class="loading-bar">
                    <div class="loading-text">Loading data...</div>
                </div>
            </div>

            <div v-if="selectedOutplantData">
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Total Cells</div><br>
                            <div class="sub-number">{{ selectedOutplantData.cells || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Total Fragments</div><br>
                            <div class="sub-number">{{ selectedOutplantData.fragments.toLocaleString() || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Species Count</div><br>
                            <div class="sub-number">{{ selectedOutplantData.species || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Mother Colonies</div><br>
                            <div class="sub-number">{{ selectedOutplantData.mother_colonies || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Corals per Cell</div><br>
                            <div class="sub-number">{{ selectedOutplantData.corals_per_cell.toFixed(0) || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Species per Cell</div><br>
                            <div class="sub-number">{{ selectedOutplantData.species_per_cell.toFixed(0) || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 100%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">YTD Outplanted</div><br>
                            <div class="sub-number">{{ selectedOutplantData.ytd_outplanted.toLocaleString() || 0 }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Species Breakdown -->
            <div class="sub" v-if="outplantData && outplantData.outplant && outplantData.outplant.outplant_species">
                <div class="chart-title">SPECIES BREAKDOWN</div>
                <div class="chart-container">
                    <canvas id="species-chart"></canvas>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sub">
                To view outplant site details, please select a site from the map
                <b-icon icon="map" size="is-small"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/events.js'
import Close from '@/components/utils/Close.vue'
import Chart from 'chart.js';

export default {
    name: 'OutplantSites',
    props: ['selectedOutplantSite'],
    components: {
        Close,
    },
    data() {
        return {
            outplantData: null,
            isLoading: false,
            speciesChart: null,
            colors: ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#333333'],
        }
    },
    computed: {
        selectedOutplantData() {
            if (!this.outplantData || !this.outplantData.outplant || !this.outplantData.outplant.info) {
                return null;
            }
            return this.outplantData.outplant.info || {};
        }
    },
    mounted() {
        if (this.selectedOutplantSite) {
            this.getOutplantData();
        }
    },
    watch: {
        selectedOutplantSite: function(newSelected) {
            if (newSelected) {
                this.getOutplantData();
            }
        }
    },
    methods: {
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        },
        async getOutplantData() {
            this.isLoading = true;
            try {
                const response = await fetch(
                    `https://data.coralgardeners.org/data/outplant?org=coral-gardeners&outplant=${this.selectedOutplantSite.id}&pwd=showmethedata`
                );
                this.outplantData = await response.json();
                this.$nextTick(() => {
                    if (this.outplantData && this.outplantData.outplant) {
                        this.initSpeciesChart();
                    }
                });
            } catch (error) {
                console.error('Error fetching outplant data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        initSpeciesChart() {
            if (this.speciesChart) this.speciesChart.destroy();

            const ctx = document.getElementById('species-chart').getContext('2d');
            const speciesData = this.outplantData.outplant.outplant_species || [];
            const data = speciesData.map(species => species.count);
            const labels = speciesData.map(species => species.taxon);

            // Generate colors dynamically
            const colors = speciesData.map((_, index) => {
                const hue = (index * 360 / speciesData.length) % 360;
                return `hsl(${hue}, 70%, 50%)`;
            });

            this.speciesChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: colors, // Use dynamically generated colors
                        borderWidth: 0,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    cutoutPercentage: 50,
                    legend: {
                        display: false,
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = tooltipItem.dataset;
                                    const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                    const currentValue = dataset.data[tooltipItem.dataIndex];
                                    const percentage = ((currentValue / total) * 100).toFixed(1);
                                    return `${labels[tooltipItem.dataIndex]}: ${percentage}%`;
                                }
                            }
                        }
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
.link-text {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    text-decoration: underline;
}
.chart-wrapper {
    padding: 10px;
    border-radius: 4px;
    max-height: 200px;
}

.reef-wrapper {
    font-family: 'Lato', sans-serif;
}

.level-wrapper {
    font-size: 16px;
    font-family: 'Riposte', sans-serif;
    padding: 10px;
}

.title {
    /* font-family: 'Office-Code-Pro', monospace; */
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 20px;
    text-align: left;
    font-size: 36px;
    color: white;
}

.sub-title {
    color: var(--primary);
    font-size: 20px;
}

.sub {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #1F1F1F;
    font-size: 14px;
    padding: 8px;
    font-family: 'Riposte', sans-serif;
}

.sub-number {
    font-size: 28px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.sub-unit {
    font-size: 12px;
}

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}

.square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 12px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.species-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.species-row:last-child {
    border-bottom: none;
}

.species-name {
    font-style: italic;
    color: #27BDF4;
}

.species-count {
    color: white;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    padding: 12px;
    position: relative;
}

.loading-bar {
    width: 100%;
    height: 8px;
    background-color: #1F1F1F;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.loading-bar::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #27BDF4;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% { left: -50%; }
    50% { left: 100%; }
    100% { left: -50%; }
}

.loading-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 14px;
    top: -20px;
    z-index: 10;
}

.chart-container {
    position: relative;
    padding: 10px;
    border-radius: 4px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>