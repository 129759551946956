<template>
    <section>
        <div class="nav-wrapper">
            <div style="text-align: left; padding: 18px;">
                <img src="@/assets/images/cg_logo.png" width="30" />
            </div>

            <div class="block nav-icon" :class="{active: page === 'layers'}" @click="changePage('layers')">
                <!-- <b-tooltip label="View map layers" position="is-right" :delay=600> -->
                <img class="custom-icon" :class="{ 'custom-icon-active': page === 'layers' }"
                    src="@/assets/icons/layers.svg" width="30" />
                <!-- </b-tooltip> -->
            </div>

            <div class="block nav-icon" @click="changePage('branches')">
                <!-- <b-tooltip label="View restored reefs" position="is-right" :delay=600> -->
                <img class="custom-icon" :class="{ 'custom-icon-active': page === 'branches' }"
                    src="@/assets/icons/branch_icon.svg" width="23" />
                <!-- </b-tooltip> -->
            </div>

            <div class="block nav-icon" @click="changePage('outplantSites')">
                <!-- <b-tooltip label="View restored reefs" position="is-right" :delay=600> -->
                <img class="custom-icon" :class="{ 'custom-icon-active': page === 'outplantSites' }"
                    src="@/assets/icons/reef.svg" width="30" />
                <!-- </b-tooltip> -->
            </div>

            <div class="block nav-icon" :class="{ active: page === 'nurseries' }" @click="changePage('nurseries')">
                <!-- <b-tooltip label="View coral nurseries" position="is-right" :delay=600> -->
                <img class="custom-icon" :class="{ 'custom-icon-active': page === 'nurseries' }"
                    src="@/assets/icons/nurseries.svg" width="30" />
                <!-- </b-tooltip> -->
            </div>

            <div class="block nav-icon" :class="{active: page === 'sensors'}" @click="changePage('sensors')">
                <!-- <b-tooltip label="View ReefOS sensor network" position="is-right" :delay=600> -->
                <img id="nav-bottom" class="custom-icon" :class="{ 'custom-icon-active': page === 'sensors' }"
                    src="@/assets/icons/camera_sidebar.svg" width="24" style="margin-left: 8px; margin-bottom: 4px;" />
                <!-- </b-tooltip> -->
            </div>

            <!-- <div class="block nav-icon" :class="{ active: page === 'analysis' }" @click="changePage('analysis')">
                <b-tooltip label="View analysis tools" position="is-right" :delay=600>
                    <img class="custom-icon" :class="{ 'custom-icon-active': page === 'analysis' }" src="@/assets/icons/analytics.svg" width="28" />
                </b-tooltip>
                </div>

            <div class="block nav-icon" :class="{active: page === 'search'}" @click="changePage('search')">
                <b-tooltip label="Search for a site" position="is-right" :delay=600>
                     <img class="custom-icon" :class="{ 'custom-icon-active': page === 'search' }" src="@/assets/icons/search.svg" width="28" />
                </b-tooltip>
            </div> -->

        </div>
    </section>
</template>

<script>
import { eventBus } from '@/events.js'

export default {
    props: ['map'],
    data() {
        return {
            page: 'layers',
        }
    },
    created() {
        eventBus.$on('closeSidebar', () => {
            this.closeSidebar();
        });
        eventBus.$on('openSidebar', (page) => {
            if (page) {
                this.page = page;
            }
            this.openSidebar();
        });
        eventBus.$on('toggleSidebar', ()=> {
            if (this.$store.state.sidebarOpen) {
                this.closeSidebar();
            } else {
                this.openSidebar();

            }
        })
    },
    mounted() {
        this.$router.push("/layers");
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: { 
        openSidebar() {
            let sidebar = document.getElementsByClassName('sidebar');
            if (!sidebar[0].classList.contains('open')) {
                sidebar[0].classList.toggle('open')
                if (this.isMobile) {
                    sidebar[0].style.bottom = '70px';
                }
                this.$store.state.sidebarOpen = true;

                if (!this.isMobile) {
                    this.map.easeTo({
                        padding: { left: 600 },
                        duration: 600 
                    });
                } else {
                    this.map.easeTo({
                        padding: { bottom: 300 },
                        duration: 600 
                    });
                }
            }
            let toggle = document.getElementsByClassName('toggle-button');
            if (!toggle[0].classList.contains('open')) {
                toggle[0].classList.toggle('open')
            }
            if (this.$route.path === '/') {
                this.$router.push( "/layers" );
                this.page = 'layers';
            }
            
        },
        closeSidebar() {
            let sidebar = document.getElementsByClassName('sidebar');
            sidebar[0].classList.toggle('open')
            // this.page = 'null'
            if (this.isMobile) {
                sidebar[0].style.bottom = '-400px';
            }
            if (!this.isMobile) {
                this.map.easeTo({
                    padding: { left: 0 },
                    duration: 600 
                });
            } else {
                this.map.easeTo({
                    padding: { bottom: 0 },
                    duration: 600 
                });
            }
            this.$store.state.sidebarOpen = false;
            let toggle = document.getElementsByClassName('toggle-button');
            if (toggle[0].classList.contains('open')) {
                toggle[0].classList.toggle('open')
            }

        },
        changePage(name) {
            if (this.page !== name) {
                this.page = name;
                this.$router.push( `/${name}` );
                this.$store.state.sidebarOpen ? '' : this.openSidebar();
            } else {
                this.closeSidebar()
            }

        },
    }
}
</script>

<style scoped>
.custom-icon {
    vertical-align: middle;
    line-height: 30px;
    margin: 0;
    padding: 0;
    margin-top: 15px;
}
.custom-icon-active {
    filter: invert(58%) sepia(33%) saturate(2558%) hue-rotate(168deg) brightness(107%) contrast(91%);
}

.custom-icon svg {
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    width: 30px;
}
.active {
    color: var(--primary);
}
.nav-icon {
    cursor: pointer;
}
</style>