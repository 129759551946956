<template>
    <div class="reef-wrapper">
        <Close />
        <div v-if="selectedBranch">
            <div class="level is-mobile title">
                <div class="level-left">
                    <div class="level-item">
                        <div style="overflow-wrap: break-word; width: 450px">
                            {{ selectedBranch.name.toUpperCase() }} <br>
                            <p class="sub-title">BRANCH</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img :src="require(`@/assets/images/branch_${selectedBranch.id}.png`)" alt="Branch Image" style="width: 100%; height: auto; padding: 20px;">
            </div>
            <!-- Boxes -->
            <div v-if="branchData">
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Nurseries</div><br>
                            <div class="sub-number">{{ branchData.branch.Nurseries }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Outplant Sites</div><br>
                            <div class="sub-number">{{ branchData.branch.Outplants }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-left: 20px;">
                            <div style="float: left;">Coral Fragments</div><br>
                            <div class="sub-number">{{ branchData.branch.Fragments.toLocaleString() }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="sub" style="margin-right: 20px;">
                            <div style="float: left;">Mother Colonies</div><br>
                            <div class="sub-number">{{ branchData.branch['Mother Colonies'].toLocaleString() }}</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sub">
                To view branch details, please select a branch from the map
                <b-icon icon="map" size="is-small"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>

import { eventBus } from '@/events.js'
import Close from '@/components/utils/Close.vue'


export default {
    name: 'Branches',
    props: ['selectedBranch'],
    components: {
        Close,
    },
    data() {
        return {
            branchData: null,
        }
    },
    mounted() {
        if (this.selectedBranch) {
            this.getBranchData();
        }
    },
    watch: {
        selectedBranch() {
            this.getBranchData();
        }
    },
    methods: {
        async getBranchData() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/branch?org=coral-gardeners&branch=${this.selectedBranch.id}&pwd=showmethedata`);
                this.branchData = await response.json();
                this.branchData.image = '';
            } catch (error) {
                console.error('Error fetching branch data:', error);
            }
        },
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        },
    },
}
</script>

<style scoped>
.link-text {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.chart-wrapper {
    padding: 10px;
    border-radius: 4px;
    max-height: 200px;
}

.reef-wrapper {
    font-family: 'Lato', sans-serif;
}

.level-wrapper {
    font-size: 16px;
    font-family: 'Riposte', sans-serif;
    padding: 10px;
}

.title {
    /* font-family: 'Office-Code-Pro', monospace; */
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 20px;
    text-align: left;
    font-size: 36px;
    color: white;
}

.sub-title {
    color: var(--primary);
    font-size: 20px;
}

.sub {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #1F1F1F;
    font-size: 14px;
    padding: 8px;
    font-family: 'Riposte', sans-serif;
}

.sub-number {
    font-size: 28px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
}

.sub-unit {
    font-size: 12px;
}

.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}

.square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 10px;
}

.legend {
    margin-left: 5px;
    font-size: 12px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
</style>