// fishUtils.js
import fishData from './fish.js';

const groupToColor = {
    'Cropping-Herbivore': '#F59E0B',
    'Concealed-Cropping-Herbivore': '#FACC15',
    'Invertivore': '#EC4899',
    'Scraping-and-Excavating-Herbivore': '#28D9C5',
    'Other': '#27BDF4',
    'Corallivore': '#A855F7'
};

export function getColorByFishClass(fishClass) {
    const fishInfo = fishData.find(fish => fish.class === fishClass);
    const groupName = fishInfo ? fishInfo.group.replace(/ /g, '-') : 'Other';
    return groupToColor[groupName];
}

export function getColorByFishGroup(fishGroup) {
    const sanitizedGroupName = fishGroup.replace(/ /g, '-');
    return groupToColor[sanitizedGroupName] || groupToColor['Other'];
}