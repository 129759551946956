<template>
    <div class="fish-card">
        <div class="content">
            <img :src="fish.image" :alt="fish.name" class="fish-image" />
            <div class="text-content">
                <div class="fish-name">{{ fish.name }}</div>
                <div class="type" :style="{ color: getColorByFishGroup(fish.group) }">{{ fish.group }}</div>
                <div class="description">{{ fish.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import fish from '@/assets/js/fish.js';
import { getColorByFishGroup } from '@/assets/js/fishUtils.js'

export default {
    props: ['selectedFish'],
    computed: {
        fish() {
            return fish.find(f => f.class === this.selectedFish);
        }
    },
    methods: {
        getColorByFishGroup,
    },
};
</script>

<style scoped>
.fish-card {
  width: 100%;
  padding: 10px;
}

.content {
  display: flex;
  align-items: center;
}

.fish-image {
  flex-shrink: 0;
  width: 80px; 
  height: auto;
  margin-right: 25px;
}

.text-content {
  flex-grow: 1;
}
.fish-name {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 5px;
}

.type {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
    color: red;
}
.description {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
}

</style>
