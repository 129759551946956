<template>
	<div>
		<Map/>
	</div>
</template>

<script>
import Map from '@/components/Map.vue'

export default {
	name: 'Home',
	components: {
		Map
	}
}
</script>
