import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sidebarOpen: false,
        layers: [],
        branches: true,
        restoredReefs: true,
        nurseries: true,
        sensors: true,
        bathymetry: false,
        openBathymetry: false,
        geomorphic: false,
        openGeomorphic: false,
        benthic: false,
        openBenthic: false,
        satellite: false,
        drone: false,
        basemap: false,
        allBenthic: ["Sand", "Rock", "Rubble", "Coral/Algae", "Seagrass", "Microalgal Mats"],
        visBenthic: [true, true, true, true, true, true],
        filteredBenthic: ["Sand", "Rock", "Rubble", "Coral/Algae", "Seagrass", "Microalgal Mats"],
    }
});