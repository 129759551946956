<template>
    <div class="sidebar-wrapper">
        <div class="level is-mobile title">
            <div class="level-left">
                <div class="level-item">
                    <div>
                        REEFOS <br>
                        <p class="sub-title camera">{{ selectedSensor.id.toUpperCase() }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="selectedSensor.reef_id" class="level is-mobile"
            style="margin-top: -15px; margin-bottom: 5px; color: #27BDF4; cursor: pointer;"
            @click="$router.push({ path: 'reefs' });">
            <div class="level-left">
                <div class="level-item">

                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <div class="link-text">Linked Restored Reef</div>
                </div>
                <div class="level-item" style="margin-right: 15px; margin-left: -10px;">
                    <b-icon icon="chevron-right"></b-icon>
                </div>
            </div>
        </div> -->
        <!-------------------------- End title ------------------------>
        <div class="sub">
            <div class="level-wrapper">
                <!---------------------------- Latest image ---------------->
                <div class="level is-mobile" style="margin-bottom: 0px; margin-top: 10px;">
                    <div class="level-left">
                        <div class="level-item name">
                            <!-- Dropdown -->
                            <b-dropdown>
                                <b-button size="is-small" slot="trigger" icon-right="menu-down"
                                    style="color: white; background-color: #404040; border: none">
                                    {{ currentOption }}
                                </b-button>
                                <b-dropdown-item v-for="option in availableOptions" :key="option"
                                    @click="currentOption = option">
                                    {{ option }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-button size="is-small" icon-left="fullscreen" type="is-primary is-light"
                                style="margin-right: 10px; color: white; background-color: #404040; border: none"
                                @click="openModal"></b-button>
                            <b-button v-if="currentOption === 'Image'"
                                :type="showDetections ? 'is-primary' : 'is-primary is-light'" size="is-small"
                                icon-left="auto-fix" @click="showDetections = !showDetections">
                                {{ showDetections ? 'Hide AI' : 'Show AI' }}
                            </b-button>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                    <div v-if="currentOption === 'Image'">
                        <div class="container">
                            <img class="latest-image" ref="image" :src="`${selectedSensor.zed}`"
                                @load="updateDimensions('image')" @click="clearBox">
                            <div v-if="selectedSensor.zed_time.seconds == selectedSensor.det_time.seconds"
                                v-for="(det, index) in selectedSensor.detections" :key="index" class="bounding-box"
                                @mouseover="hoverOverBox(index)" @mouseleave="hoverOverBox(null)"
                                @click="activateBox(index, det)"
                                :class="{ active: index === hoverBoxIndex || index === activeBoxIndex}" :style="{
                                    top: (det.xyxy[1] / originalHeight * actualHeight - buffer) + 'px', 
                                    left: (det.xyxy[0] / originalWidth * actualWidth - buffer) + 'px', 
                                    width: ((det.xyxy[2] - det.xyxy[0]) / originalWidth * actualWidth + 2*buffer) + 'px', 
                                    height: ((det.xyxy[3] - det.xyxy[1]) / originalHeight * actualHeight + 2*buffer) + 'px',
                                    display: showDetections ? 'block' : 'none',
                                    color: getColorByFishClass(det.class),
                                }">
                                <span class="box-label">{{ prettifyFishName(det.class) }}</span>
                            </div>
                        </div>
                        <div style="font-size: 12px; color: #7B7B7B; margin-top: 5px">
                            <span>Image updated at {{ selectedSensor.zed_time.toDate().toLocaleString() }}</span>
                        </div>
                        <div>
                            <div v-if="showDetections && selectedSensor.zed_time.seconds !== selectedSensor.det_time.seconds"
                                class="detection-info" style="margin-top: 10px">
                                No AI results for this image
                            </div>
                            <div v-if="showDetections && selectedSensor.detections.length == 0" class="detection-info"
                                style="margin-top: 10px">
                                No fish detected
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentOption === 'Depth Map'">
                        <img class="latest-image" ref="image" :src="`${selectedSensor.zed_depth}`">
                        <div style="font-size: 12px; color: #7B7B7B; margin-top: 5px">
                            <span>Depth image updated at {{ selectedSensor.zed_depth_time.toDate().toLocaleString()
                                }}</span>
                        </div>
                    </div>
                    <div v-else-if="currentOption === 'Anomaly Video'">
                        <video :key="Date.now()" class="latest-image" autoplay muted playsinline loop controls>
                            <source :src="`${selectedSensor.anomaly_video}`" type="video/mp4" />
                        </video>
                        <div style="font-size: 12px; color: #7B7B7B; margin-top: 5px">
                            <span>Video updated at {{ selectedSensor.anomaly_video_time.toDate().toLocaleString()
                                }}</span>
                        </div>
                    </div>
                    <div v-else-if="currentOption === 'Timelapse'">
                        <video :key="Date.now()" class="latest-image" autoplay muted playsinline controls>
                            <source :src="`${selectedSensor.zed_timelapse.url}`" type="video/mp4" />
                        </video>
                        <div style="font-size: 12px; color: #7B7B7B; margin-top: 5px">
                            <div>From {{ selectedSensor.zed_timelapse.start_time.toDate().toLocaleString() }} to {{
                                selectedSensor.zed_timelapse.end_time.toDate().toLocaleString() }}</div>
                        </div>
                    </div>
                    <div v-else-if="currentOption === 'Livestream'">
                        <iframe style="width: 100%; height: 227px"
                            :src="`https://camstreamer.com/embed/${selectedSensor.camstreamer_id}`" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
                <AudioCard :audioUrl="selectedSensor.audio" :audioTime="selectedSensor.audio_time" />
                <b-modal v-model="isModalActive" custom-class="modal-class">
                    <div v-if="currentOption === 'Image'">
                        <div class="container">
                            <img class="latest-image" ref="modalImage" :src="`${selectedSensor.zed}`"
                                alt="Full-size image" @load="updateDimensions('modal')" @click="clearBox" />
                            <div v-if="selectedSensor.zed_time.seconds == selectedSensor.det_time.seconds"
                                v-for="(det, index) in selectedSensor.detections" :key="index" class="bounding-box"
                                @mouseover="hoverOverBox(index)" @mouseleave="hoverOverBox(null)"
                                @click="activateBox(index, det)"
                                :class="{ active: index === hoverBoxIndex || index === activeBoxIndex}" :style="{
                                top: (det.xyxy[1] / originalHeight * modalActualHeight - buffer) + 'px', 
                                left: (det.xyxy[0] / originalWidth * modalActualWidth - buffer) + 'px', 
                                width: ((det.xyxy[2] - det.xyxy[0]) / originalWidth * modalActualWidth + 2*buffer) + 'px', 
                                height: ((det.xyxy[3] - det.xyxy[1]) / originalHeight * modalActualHeight + 2*buffer) + 'px',
                                display: showDetections ? 'block' : 'none',
                                color: getColorByFishClass(det.class),
                                }">
                                <span class="box-label">{{ prettifyFishName(det.class) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentOption === 'Depth Map'">
                        <img class="latest-image" ref="modalImage" :src="`${selectedSensor.zed_depth}`">
                    </div>
                    <div v-else-if="currentOption === 'Anomaly Video'">
                        <video :key="Date.now()" class="latest-image" autoplay muted playsinline loop controls>
                            <source :src="`${selectedSensor.anomaly_video}`" type="video/mp4" />
                        </video>
                    </div>
                    <div v-else-if="currentOption === 'Timelapse'">
                        <video :key="Date.now()" class="latest-image" autoplay muted playsinline controls>
                            <source :src="`${selectedSensor.zed_timelapse.url}`" type="video/mp4" />
                        </video>
                    </div>
                </b-modal>
            </div>
        </div>
        <!----------------------- AI Results ----------------------->
        <div class="sub" v-if="selectedFish && selectedFish != 'all_classes' && selectedFish != 'fish'">
            <FishDetailCard :selectedFish="selectedFish" />
        </div>
        <!-----------------------Species Chart----------------------->
        <div v-show="chart_species_show" class="sub">
            <div class="level is-mobile" style="margin-bottom: 5px;">
                <div class="level-left">
                    <div class="level-item">
                        <div class="chart-title">FUNCTIONAL GROUP</div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <!-- Dropdown -->
                        <b-dropdown v-if="fishList.length > 0">
                            <b-button size="is-small" slot="trigger" icon-right="menu-down"
                                style="color: white; background-color: #404040; border: none">
                                {{ prettifyFishName(selectedFish) }}
                            </b-button>
                            <b-dropdown-item v-for="fish in sortedFishList" :key="fish" @click="selectedFish = fish">
                                {{ prettifyFishName(fish) }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <canvas class="chart-wrapper" id="chart-species"></canvas>
        </div>
        <!-----------------------Abundance Chart----------------------->
        <div v-show="chart_abundance_show" class="sub">
            <div class="level is-mobile" style="margin-bottom: 5px;">
                <div class="level-left">
                    <div class="level-item">
                        <div class="chart-title">ABUNDANCE</div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <b-dropdown v-model="chartLength" position="is-bottom-left" aria-role="list" disabled>
                            <template v-if="chartLength === 'Week'" #trigger="{ active }">
                                <b-button label="Week" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Day'" #trigger="{ active }">
                                <b-button label="Day" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Month'" #trigger="{ active }">
                                <b-button label="Month" size="is-small"
                                    :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Year'" #trigger="{ active }">
                                <b-button label="Year" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <b-dropdown-item aria-role="listitem" :value="'Day'">Day</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Week'">Week</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Month'">Month</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Year'">Year</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <canvas class="chart-wrapper" id="chart-abundance"></canvas>
        </div>
        <!-----------------------Temp Chart----------------------->
        <div v-show="chart_temp_show" class="sub">
            <div class="level is-mobile" style="margin-bottom: 5px;">
                <div class="level-left">
                    <div class="level-item">
                        <div class="chart-title">TEMPERATURE (°C)</div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <b-dropdown v-model="chartLength" position="is-bottom-left" aria-role="list" disabled>
                            <template v-if="chartLength === 'Week'" #trigger="{ active }">
                                <b-button label="Week" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Day'" #trigger="{ active }">
                                <b-button label="Day" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Month'" #trigger="{ active }">
                                <b-button label="Month" size="is-small"
                                    :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Year'" #trigger="{ active }">
                                <b-button label="Year" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <b-dropdown-item aria-role="listitem" :value="'Day'">Day</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Week'">Week</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Month'">Month</b-dropdown-item>
                            <!-- <b-dropdown-item aria-role="listitem" :value="'Year'">Year</b-dropdown-item> -->
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <canvas class="chart-wrapper" id="chart-temp"></canvas>
        </div>
        <!-----------------------End Chart----------------------->
    </div>
</template>

<script>
const { InfluxDB, FluxQuery, flux } = require('@influxdata/influxdb-client')
const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ=='
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token })
const queryApi = client.getQueryApi('ReefOS')
import moment from 'moment';
import FishDetailCard from './cards/FishDetailCard.vue';
import AudioCard from './cards/AudioCard.vue';
import { getColorByFishClass } from '@/assets/js/fishUtils.js'


export default {
    name: 'Camera',
    components: {
        FishDetailCard, 
        AudioCard,
    },
    props: ['selectedSensor'],
    data() {
        return {
            // allOptions: ['Recent Image', 'Recent Video', 'Recent Timelapse'],
            allOptions: ['Image', 'Depth Map', 'Livestream', 'Timelapse'],
            currentOption: 'Image',
            img: null,
            openImage: true,
            isModalActive: false,
            showDetections: false,
            originalWidth: 1920,  
            originalHeight: 1080, 
            actualWidth: 0,
            actualHeight: 0,
            modalActualWidth: 0,
            modalActualHeight: 0,
            buffer: 4,
            hoverBoxIndex: null,
            activeBoxIndex: null,
            activeBox: null,
            chartLength: 'Month',
            ticks: 7,
            length: 7,
            bleaching: 29.8,
            fishList: [],
            selectedFish: 'all_classes',
            chart_temp: null,
            chart_abundance: null,
            chart_species: null,
            chart_temp_show: false,
            chart_abundance_show: false,
            chart_species_show: false,
            model_version: 4,
            temp_bias: 0.55,
        }
    },
    mounted() {
        this.getSensorData();
        this.getAbundanceData();
        this.getFishList();
    },
    watch: {
        selectedSensor: function (newVal, oldVal) {
            console.log('selectedSensor changed')
            this.getSensorData();
            this.getAbundanceData();
            this.getFishList();
            this.chart_temp_show = false;
            this.chart_abundance_show = false;
            this.chart_species_show = false;
        },
        selectedFish: function (newVal, oldVal) {
            this.getSpeciesData();
        },
        activeBox: function (newVal, oldVal) {
            if (newVal) {
                this.selectedFish= newVal.class;
            }
        },
        isModalActive(newVal, oldVal) {
            // This is needed for the modal to be fullscreen on Safari
            // otherwise it's contained within sidebar
            if (newVal) {
                document.querySelector('.sidebar').style.overflowY = 'hidden';
            } else {
                document.querySelector('.sidebar').style.overflowY = 'auto';
            }
        },
    },
    computed: {
        availableOptions() {
            return this.allOptions.filter(option => option !== this.currentOption)
        },
        sortedFishList() {
            return this.fishList.sort((a, b) => this.prettifyFishName(a).localeCompare(this.prettifyFishName(b)));
        },
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: {
        getColorByFishClass,
        prettifyFishName(name) {
            return name
                .replace(/_/g, ' ')
                .replace(/\b\w/g, l => l.toUpperCase());
        },
        openModal() {
            this.isModalActive = true;
        }, 
        updateDimensions(type) {
            if (type === 'image') {
                this.actualWidth = this.$refs.image.offsetWidth;
                this.actualHeight = this.$refs.image.offsetHeight;
            } else if (type === 'modal') {
                this.modalActualWidth = this.$refs.modalImage.offsetWidth;
                this.modalActualHeight = this.$refs.modalImage.offsetHeight;
            }
        },
        hoverOverBox(index) {
            this.hoverBoxIndex = index;
        },
        clearBox() {
            this.activeBoxIndex = null;
            this.hoverBoxIndex = null;
            this.activeBox = null;
        },
        activateBox(index, det) {
            this.activeBoxIndex = index;
            this.activeBox = det;
        },
        getSensorData() {
            let sensorData = [];
            let weekdays = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
            const query = flux`from(bucket: "${this.selectedSensor.id}")
                |> range(start: -3mo)
                |> filter(fn: (r) => r._measurement == "sensors" and r._field == "externalTemperature")
                |> aggregateWindow(every: 1h, fn: mean, createEmpty: false)
                |> sort(columns: ["_time"])
                |> yield(name: "mean")`
            queryApi.queryRows(query, {
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row);
                    sensorData.push({
                        temp: (parseFloat(o._value.toFixed(2)) - parseFloat(this.temp_bias)).toFixed(2),
                        timestamp: new Date(o._time),
                        label: weekdays[new Date(o._time).getDay()],
                        bleaching: this.bleaching,
                    });

                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    // Sort the array of objects by timestamp
                    sensorData.sort((a, b) => a.timestamp - b.timestamp);
                    const chartData = {
                        temp: sensorData.map(d => d.temp),
                        timestamp: sensorData.map(d => d.timestamp),
                        label: sensorData.map(d => d.label),
                        bleaching: sensorData.map(d => d.bleaching),
                    };
                    this.initTempChart(chartData);
                    if (sensorData.length > 1) {
                        this.chart_temp_show = true;
                    }
                },
            });
        },
        getAbundanceData() {
            let abundanceData = [];
            let weekdays = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
            const query = flux`from(bucket: "${this.selectedSensor.id}")
                |> range(start: -3mo)
                |> filter(fn: (r) => r["_measurement"] == "class_detection")
                |> filter(fn: (r) => r["_field"] == "count")
                |> filter(fn: (r) => r.model == "reefos-01")
                |> filter(fn: (r) => r.version == "${this.model_version}")
                |> filter(fn: (r) => r["class"] == "all_classes")
                |> aggregateWindow(every: 3h, fn: mean, createEmpty: false)
                |> sort(columns: ["_time"])
                |> yield(name: "mean")`
            queryApi.queryRows(query, {
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row);
                    abundanceData.push({
                        count: o._value.toFixed(1),
                        timestamp: new Date(o._time),
                        label: weekdays[new Date(o._time).getDay()],
                    });

                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    abundanceData.sort((a, b) => a.timestamp - b.timestamp);
                    const chartData = {
                        count: abundanceData.map(d => d.count),
                        timestamp: abundanceData.map(d => d.timestamp),
                        label: abundanceData.map(d => d.label),
                    };
                    this.initAbundanceChart(chartData);
                    if (abundanceData.length > 1) {
                        this.chart_abundance_show = true;
                    }
                },
            });

        },
        getFishList() {
            const uniqueClasses = new Set()
            const query = flux`from(bucket: "${this.selectedSensor.id}")
                |> range(start: -3mo)
                |> filter(fn: (r) => r["_measurement"] == "class_detection")
                |> filter(fn: (r) => r["_field"] == "count")
                |> filter(fn: (r) => r.model == "reefos-01")
                |> filter(fn: (r) => r.version == "${this.model_version}")
                |> aggregateWindow(every: 3h, fn: mean, createEmpty: false)
                |> yield(name: "mean")`
            queryApi.queryRows(query, {
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row);
                    uniqueClasses.add(o.class);

                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    this.fishList = Array.from(uniqueClasses);
                    this.selectedFish = this.fishList[0];
                    this.getSpeciesData();
                },
            });
        },
        getSpeciesData() {
            let speciesData = [];
            let weekdays = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];
            const query = flux`from(bucket: "${this.selectedSensor.id}")
                |> range(start: -3mo)
                |> filter(fn: (r) => r["_measurement"] == "class_detection")
                |> filter(fn: (r) => r["_field"] == "count")
                |> filter(fn: (r) => r.model == "reefos-01")
                |> filter(fn: (r) => r.version == "${this.model_version}")
                |> filter(fn: (r) => r["class"] == "${this.selectedFish}")
                |> aggregateWindow(every: 3h, fn: mean, createEmpty: false)
                |> sort(columns: ["_time"])
                |> yield(name: "mean")`
            queryApi.queryRows(query, {
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row);
                    speciesData.push({
                        count: o._value.toFixed(1),
                        timestamp: new Date(o._time),
                        label: weekdays[new Date(o._time).getDay()],
                    });

                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    speciesData.sort((a, b) => a.timestamp - b.timestamp);
                    const chartData = {
                        count: speciesData.map(d => d.count),
                        timestamp: speciesData.map(d => d.timestamp),
                        label: speciesData.map(d => d.label),
                    };
                    this.initSpeciesChart(chartData);
                    if (speciesData.length > 1) {
                        this.chart_species_show = true;
                    }
                },
            });

        },
        initTempChart(chartData) {
            if (this.chart_temp) {
                this.chart_temp.destroy();
            }
            let ctx = document.getElementById('chart-temp').getContext("2d");
            let blue_black_gradient = ctx.createLinearGradient(0, 0, 0, 350);
            blue_black_gradient.addColorStop(0, 'rgba(17, 173, 227, 1)');
            blue_black_gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            this.chart_temp = new Chart(ctx, {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            position: 'bottm',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: false,
                                // maxTicksLimit: this.ticks,
                                maxRotation: 0,
                                callback: function (tick, index, ticks) {
                                    if (index === 0 || index === ticks.length - 1) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    // Middle tick
                                    const middleIndex = Math.floor(ticks.length / 2);
                                    if (index === middleIndex) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    return null;
                                },
                            },
                            gridLines: {
                                display: false,
                                drawBorder: true,
                                color: '#545454',

                            },
                        }],
                        yAxes: [{
                            display: true,
                            position: 'left',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 6,
                                callback: function (value, index, array) {
                                    return value + '   ';
                                }
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                                color: '#545454',
                            }
                        }],
                    },
                    tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            // use label callback to return the desired label
                            label: function (tooltipItem, data) {
                                return (tooltipItem.yLabel + ' °C');
                            },
                            // remove title
                            title: function (tooltipItem, data) {
                                let timestamp = chartData.timestamp[tooltipItem[0].index];
                                let formatted = new Date(timestamp);
                                let shortened = formatted.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
                                return shortened
                            }
                        }
                    }
                },
                data: {
                    labels: chartData.timestamp,
                    datasets: [
                        {
                            label: 'temp',
                            pointRadius: 0,
                            fill: true,
                            borderColor: '#11ADE3',
                            backgroundColor: blue_black_gradient,
                            hoverBorderWidth: 2,
                            borderWidth: 2,
                            data: chartData.temp,
                        },
                        {
                            label: 'bleaching threshold',
                            fill: false,
                            borderColor: 'red',
                            borderWidth: 2,
                            pointRadius: 0,
                            data: chartData.bleaching,
                        },
                    ],
                }
            });
        },
        initAbundanceChart(chartData) {
            if (this.chart_abundance) {
                this.chart_abundance.destroy();
            }
            let ctx = document.getElementById('chart-abundance').getContext("2d");
            let blue_black_gradient = ctx.createLinearGradient(0, 0, 0, 350);
            blue_black_gradient.addColorStop(0, 'rgba(17, 173, 227, 1)');
            blue_black_gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            this.chart_abundance = new Chart(ctx, {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            position: 'bottm',
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: false,
                                // maxTicksLimit: this.ticks,
                                maxRotation: 0,
                                callback: function (tick, index, ticks) {
                                    if (index === 0 || index === ticks.length - 1) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    // Middle tick
                                    const middleIndex = Math.floor(ticks.length / 2);
                                    if (index === middleIndex) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    return null;
                                },
                            },
                            gridLines: {
                                display: false,
                                drawBorder: true,
                                color: '#545454',

                            },
                        }],
                        yAxes: [{
                            display: true,
                            position: 'left',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 6,
                                callback: function (value, index, array) {
                                    return value + '   ';
                                }
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                                color: '#545454',
                            }
                        }],
                    },
                    tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            // use label callback to return the desired label
                            label: function (tooltipItem, data) {
                                return (tooltipItem.yLabel + ' fish');
                            },
                            // remove title
                            title: function (tooltipItem, data) {
                                let timestamp = chartData.timestamp[tooltipItem[0].index];
                                let formatted = new Date(timestamp);
                                let shortened = formatted.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
                                return shortened
                            }
                        }
                    }
                },
                data: {
                    labels: chartData.timestamp,
                    datasets: [
                        {
                            label: 'abundace',
                            pointRadius: 0,
                            fill: true,
                            borderColor: '#11ADE3',
                            backgroundColor: blue_black_gradient,
                            hoverBorderWidth: 2,
                            borderWidth: 2,
                            data: chartData.count,
                        },
                    ],
                }
            });
        },
        initSpeciesChart(chartData) {
            if (this.chart_species) {
                this.chart_species.destroy();
            }
            let ctx = document.getElementById('chart-species').getContext("2d");
            let blue_black_gradient = ctx.createLinearGradient(0, 0, 0, 350);
            blue_black_gradient.addColorStop(0, 'rgba(17, 173, 227, 1)');
            blue_black_gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            this.chart_species = new Chart(ctx, {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            position: 'bottm',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: false,
                                // maxTicksLimit: 2,
                                maxRotation: 0,
                                callback: function(tick, index, ticks) {
                                    if (index === 0 || index === ticks.length - 1) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    // Middle tick
                                    const middleIndex = Math.floor(ticks.length / 2);
                                    if (index === middleIndex) {
                                        return moment(tick).format('M/D/YY');
                                    }
                                    return null;
                                },
                            },
                            gridLines: {
                                display: false,
                                drawBorder: true,
                                color: '#545454',

                            },
                        }],
                        yAxes: [{
                            display: true,
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 6,
                                callback: function (value, index, array) {
                                    return value + '   ';
                                }
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                                color: '#545454',
                            }
                        }],
                    },
                    tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            // use label callback to return the desired label
                            label: function (tooltipItem, data) {
                                return (tooltipItem.yLabel + ' fish');
                            },
                            // remove title
                            title: function (tooltipItem, data) {
                                let timestamp = chartData.timestamp[tooltipItem[0].index];
                                let formatted = new Date(timestamp);
                                let shortened = formatted.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
                                return shortened
                            }
                        }
                    }
                },
                data: {
                    labels: chartData.timestamp,
                    datasets: [
                        {
                            label: 'abundace',
                            pointRadius: 0,
                            fill: true,
                            borderColor: getColorByFishClass(this.selectedFish),
                            // backgroundColor: blue_black_gradient,
                            hoverBorderWidth: 2,
                            borderWidth: 2,
                            data: chartData.count,
                        },
                    ],
                }
            });
        },
    }
}

</script>

<style scoped>
.container {
    position: relative;
}

.link-text {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.bounding-box {
    position: absolute;
    border: 2px solid;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
}
.bounding-box.active {
    border: 2px solid white;
}
.box-label {
    display: none;
    position: absolute;
    bottom: 100%; /* This positions the text above the box */
    left: -2px;
    color: white;
    font-size: 14px;
    padding: 2px 0px;
    text-transform: capitalize;
}

.bounding-box:hover .box-label, 
.bounding-box.active .box-label {
    display: block;
}
.camera {
    color: var(--camera);
}

.latest-image {
    border-radius: 4px;
}
.timestamp {
    font-size: 12px;
    margin-left: 10px;
    background-color: #1F1F1F;
    border-radius: 12px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    color: #686969
}

.detection-info {
    text-align: left;
    text-transform: capitalize;
    color: var(--light-gray)
}

#chart {
    width: 340px;
    height: 250px !important;
    padding: 10px;
    border-radius: 4px;
    /* border: 1px solid #545454; */
}
.chart-wrapper {
    width: 340px;
    height: 250px !important;
    padding: 10px;
    border-radius: 4px;
}
.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}
</style>