<template>
    <div class="sidebar-wrapper">
        <div class="level is-mobile title">
            <div class="level-left">
                <div class="level-item">
                    <div>
                        {{ selectedSensor.id }} <br>
                        <p class="sub-title buoy">{{ selectedSensor.type.toUpperCase() }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-------------------------- End title ------------------------>
        <div style="text-align: left; color: #7B7B7B; margin-left: 10px;">
            Data provided by <a href="https://www.aqualink.org" target="_blank"><u>Aqualink.org</u></a></div>
        <div class="sub">
            <div class="level is-mobile" style="margin-bottom: 5px;">
                <div class="level-left">
                    <div class="level-item">
                        <div class="chart-title">TEMPERATURE</div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <b-dropdown v-model="chartLength" position="is-bottom-left" aria-role="list">
                            <template v-if="chartLength === 'Week'" #trigger="{ active }">
                                <b-button label="Week" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Day'" #trigger="{ active }">
                                <b-button label="Day" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Month'" #trigger="{ active }">
                                <b-button label="Month" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <template v-else-if="chartLength === 'Year'" #trigger="{ active }">
                                <b-button label="Year" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
                            </template>
                            <b-dropdown-item aria-role="listitem" :value="'Day'">Day</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Week'">Week</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" :value="'Month'">Month</b-dropdown-item>
                            <!-- <b-dropdown-item aria-role="listitem" :value="'Year'">Year</b-dropdown-item> -->
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <canvas class="chart-wrapper" id="chart"></canvas>
        </div>
        <!------------------------------ End chart  ----------------------------------------------->
        <div class="sub">
            <div class="level is-mobile" style="margin-bottom: 0px;">
                <div class="level-left">
                    <div class="level-item">
                        <div class="chart-title">BUOY OBSERVATION</div>
                    </div>
                </div>
            </div>
            <!--- boxes -------->
            <div class="clearfix">
                <div style="float: left; width: 50%;">
                    <div class="sub-dark">
                        <div style="float: left;">Temp at 1m</div><br>
                        <div class="sub-number">{{ selectedSensor.top_temp }}<span class="sub-unit"> °C</span></div>
                        <div style="clear: both;"></div>
                    </div>
                    <div class="sub-dark">
                        <div style="float: left;">Temp at {{ selectedSensor.depth }}m</div><br>
                        <div class="sub-number">{{ selectedSensor.bottom_temp }}<span class="sub-unit"> °C</span></div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="float: left; width: 50%;">
                    <img class="buoy-icon" src="@/assets/icons/aqualink.svg" />
                </div>
            </div>
        </div>
        <!------------------------------ End observation ------------------------------------------>
        <div class="sub">
            <div class="level is-mobile" style="margin-bottom: 0px;">
                <div class="level-left">
                    <div class="level-item" style="margin-left: 10px;">
                        <img class="buoy-icon" src="@/assets/icons/wind.svg" width="24" />
                        <div class="chart-title">WIND</div>
                    </div>
                </div>
            </div>
            <!--- boxes -------->
            <div class="clearfix">
                <div style="float: left; width: 50%;">
                    <div class="sub-dark">
                        <div style="float: left;">Speed</div><br>
                        <div class="sub-number">{{ selectedSensor.wind[0].speed }}<span class="sub-unit"> kts</span></div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="float: left; width: 50%;">
                    <div class="sub-dark">
                        <div style="float: left;">Direction</div><br>
                        <div class="sub-number">{{ selectedSensor.wind[0].direction }}<span>°</span></div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
            </div>
            <div class="level is-mobile" style="margin-bottom: 0px; margin-top: 10px;">
                <div class="level-left">
                    <div class="level-item" style="margin-left: 10px;">
                        <img class="buoy-icon" src="@/assets/icons/waves.svg" width="24" />
                        <div class="chart-title">WAVES</div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <div style="float: left; width: 33%;">
                    <div class="sub-dark">
                        <div style="float: left;">Height</div><br>
                        <div class="sub-number">{{ selectedSensor.waves[0].significantWaveHeight.toFixed(1) }}<span
                                class="sub-unit"> m</span></div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="float: left; width: 33%;">
                    <div class="sub-dark">
                        <div style="float: left;">Period</div><br>
                        <div class="sub-number">{{ selectedSensor.waves[0].meanPeriod.toFixed(1) }}<span class="sub-unit">
                                s</span></div>
                        <div style="clear: both;"></div>
                    </div>
                </div>
                <div style="float: left; width: 33%;">
                    <div class="sub-dark">
                        <div style="float: left;">Direction</div><br>
                        <div class="sub-number">{{ selectedSensor.waves[0].meanDirection.toFixed(0) }}<span>°</span></div>
                        <div style="clear: both;"></div>
                        </div>
                    </div>
                </div>
                <!------------------------------ End boxes  ----------------------------------------------->
            </div>
        <!------------------------------ End wind  ----------------------------------------------->
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
    name: 'Buoy',
    props: ['selectedSensor'],
    data() {
        return {
            page: 'sensors',
            chartLength: 'Week',
            ticks: 7,
            length: 7,
            token: 'c1c866d05b045ce586fa77e6fc9dc2',
            bleaching_threshold: 29.9, // This is also defined in Map.vue
        }
    },
    mounted() {
        if (this.selectedSensor) {
            this.initChart(this.selectedSensor.temp['Week']);
        }
    },
    watch: {
        selectedSensor: function (new_selected) {
            this.$nextTick(() => {
                if (this.chart) {
                    this.chart.destroy();
                }
                this.initChart(new_selected.temp['Week']);
            });
        },
        chartLength: function(length) {
            this.changeAxis(length)
        }
    },
    methods: {
        changeAxis(length) {
            if (length == 'Day') {
                this.length = 1;
                this.ticks = 1;
            } else if (length == 'Week') {
                this.length = 7;
                this.ticks = 7;
            } else if (length == 'Month') {
                this.length = 30;
                this.ticks = 11;
            } else if (length == 'Year') {
                this.length = 365;
                this.ticks = 11;
            }
          
            let ct = new Date();
            ct.setDate(ct.getDate() - this.length);
            let start = ct.toISOString();
            this.chart.destroy();
            if (this.selectedSensor.temp[this.chartLength].bottomTemp.length === 0) {
                console.log('Grabbing data for ', this.chartLength)
                this.getAqualinkData(start, new Date().toISOString());
            } else {
                console.log('Already have data for ', this.chartLength)
                this.initChart(this.selectedSensor.temp[this.chartLength]);
            }
        },
        getAqualinkData(start, end) {
            let weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            fetch(`https://api.sofarocean.com/api/sensor-data?spotterId=${this.selectedSensor.id}&startDate=${start}&endDate=${end}&token=${this.token}`)
                .then(response => response.json()).then(response => {
                    response.data.forEach(data => {
                        if (data.sensorPosition == 2) {
                            this.selectedSensor.temp[this.chartLength].bottomTemp.push(data.value.toFixed(2));
                            let date = new Date(data.timestamp).getDay();
                            this.selectedSensor.temp[this.chartLength].label.push(weekdays[date]);
                            this.selectedSensor.temp[this.chartLength].timestamp.push(data.timestamp);
                            this.selectedSensor.temp[this.chartLength].bleaching.push(this.bleaching_threshold);
                        }
                    });
                    this.initChart(this.selectedSensor.temp[this.chartLength]);
                });
        },
        initChart(chartData) {
            let ctx = document.getElementById('chart').getContext("2d");
            let blue_black_gradient = ctx.createLinearGradient(0, 0, 0, 350);
            blue_black_gradient.addColorStop(0, 'rgba(17, 173, 227, 1)');
            blue_black_gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            this.chart = new Chart(ctx, {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            position: 'bottm',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: this.ticks,
                                maxRotation: 0,
                                // callback: function(tick, index, array) {
                                //     return (index % 10) ? "" : tick;
                                // }
                            },
                            gridLines: {
                                display: false,
                                drawBorder: true,
                                color: '#545454',

                            },
                        }],
                        yAxes: [{
                            display: true,
                            position: 'left',
                            ticks: {
                                display: true,
                                fontSize: 12,
                                fontColor: 'white',
                                fontStyle: 'normal',
                                autoSkip: true,
                                maxTicksLimit: 6,
                                callback: function (value, index, array) {
                                    return value + '   ';
                                }
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                                color: '#545454',
                            }
                        }],
                    },
                    tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            // use label callback to return the desired label
                            label: function (tooltipItem, data) {
                                return (tooltipItem.yLabel + ' °C');
                            },
                            // remove title
                            title: function (tooltipItem, data) {
                                let timestamp = chartData.timestamp[tooltipItem[0].index];
                                let formatted = new Date(timestamp);
                                let shortened = formatted.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'});
                                return shortened
                            }
                        }
                    }
                },
                data: {
                    labels: chartData.label,
                    datasets: [
                        {
                            label: 'temp',
                            pointRadius: 0,
                            fill: true,
                            borderColor: '#11ADE3',
                            backgroundColor: blue_black_gradient,
                            hoverBorderWidth: 2,
                            borderWidth: 2,
                            data: chartData.bottomTemp,
                        },
                        {
                            label: 'bleaching threshold',
                            fill: false,
                            borderColor: 'red',
                            borderWidth: 2,
                            pointRadius: 0,
                            data: chartData.bleaching,
                        },
                    ],
                }
            });
        },
    },
}
</script>

<style scoped>
#chart {
    width: 340px;
    height: 250px !important;
    padding: 10px;
    border-radius: 4px;
    /* border: 1px solid #545454; */

}
.chart-wrapper {
    width: 340px;
    height: 250px !important;
    padding: 10px;
    border-radius: 4px;
}
.chart-title {
    font-size: 16px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    padding: 5px;
}
.buoy-icon {
    margin-right: 10px;
}
.buoy {
    color: var(--buoy);
}
.button.is-small:not(.is-rounded) {
    border-radius: 4px;
    background-color: #404040;
    color: white;
    border-color: #404040;
}

</style>