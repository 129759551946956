<template>
	<div class="wrapper">
		<div class="nav">
			<Nav :map="map" />
		</div>
		<div class="sidebar">
			<div id="draggable-bar" class="draggable-bar" @touchstart="touchStart" @touchmove="touchMove"
				@touchend="touchEnd"></div>
			<div class="sidebar-content">
				<router-view 
					:selectedBranch="selectedBranch"
					:selectedOutplantSite="selectedOutplantSite" 
					:selectedSensor="selectedSensor"
					:selectedNursery="selectedNursery" 
					:sensor_markers="sensor_markers"
					:nursery_markers="nursery_markers" 
					:branch_markers="branch_markers"
					:map="map" 
				/>
			</div>
		</div>
		<div class="toggle-button" @click="toggleSidebar()" style="display: flex; align-items: center;">
			<b-icon :icon="$store.state.sidebarOpen ? 'chevron-left' : 'chevron-right'"></b-icon>
		</div>

		<Dropdown v-if="selectedBranch" v-model="selectedBranch" :options="branchOptions" @input="changeBranch" />

		<div id="map" />

	</div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { db } from '@/main.js'
import { db_RestorationApp } from '@/main.js'
import { collection, getDocs } from "firebase/firestore";
import Nav from '@/components/Nav.vue'
import Dropdown from '@/components/utils/Dropdown.vue';
import { eventBus } from '@/events.js'
import turf from 'turf/turf'

export default {
	name: 'Map',
	components: {
		Nav,
		Dropdown,
	},
	props: {
	},
	data() {
		return {
			access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
			center: [-149.820850, -17.489806], // Mo'orea
			map: null,
			// spotterIDs: ['SPOT-30817C', 'SPOT-30818C'],
			spotterIDs: ['SPOT-30817C'],
			spotterDepths: { 'SPOT-0797': 1, 'SPOT-30817C': 2, 'SPOT-30818C': 3 },
			token: 'c1c866d05b045ce586fa77e6fc9dc2',
			sensor_markers: [],
			hoveredStateId: null,
			bleaching_threshold: 29.9, // this is also defined in Buoy.vue 
			selectedSensor: null,
			sensors: [],
			selectedOutplantSite: null,
			outplantSites: [],
			selectedNursery: null,
			nurseries: [],
			nursery_markers: [],
			popupButton: null,
			toggleButton: false,
			startY: 0,
			currentY: 0,
			isDragging: false,
			sidebarEl: null,
			initialBottom: -400,
			startTime: 0,
			ocotpusMarker: [],
			droneLayers: ['airport_north_22cm', 'airport_south', 'papetoaireef_east',
				'papetoaireef_west', 'paraororeef_east', 'paraororeef_west', 'hiltonreef_east',
				'hiltonreef_west', 'tipaniersreef_1', 'tipaniersreef_2'],
			branches: [],
			selectedBranch: null,
			branch_markers: [],
		};
	},
	mounted() {
		this.createMap();
		this.sidebarEl = document.getElementsByClassName('sidebar')[0];
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	computed: {
		allMarkers() {
			return this.sensor_markers.concat(this.nursery_markers, this.ocotpusMarker);
		},
		branchOptions() {
			return this.branches.map(branch => ({
				name: branch.branch.name,
				id: branch.branch.id
			}));
		},
	},
	methods: {
		changeBranch() {
			const selectedConfig = this.branches.find(branch => branch.branch.name === this.selectedBranch.name);
			if (selectedConfig) {
				this.map.flyTo({
					center: [selectedConfig.branch.geoLocation.longitude, selectedConfig.branch.geoLocation.latitude],
					zoom: 12,
					pitch: 60.54,
					bearing: 148.83,
				});
			}
		},
		toggleSidebar() {
			this.toggleButton = !this.toggleButton;
			eventBus.$emit('toggleSidebar');
		},
		touchStart(e) {
			this.startY = e.touches[0].clientY;
			this.isDragging = true;
			this.startTime = new Date().getTime();
			this.sidebarEl.style.transition = 'none';
		},
		touchMove(e) {
			if (!this.isDragging) return;
			e.preventDefault();
			e.stopPropagation();

			this.currentY = e.touches[0].clientY;
			let diff = this.startY - this.currentY;

			if (this.startY > this.currentY) {
				// user is draggin up
				if (this.$store.state.sidebarOpen) {
					this.sidebarEl.style.height = 500 + diff + 'px';
				} else {
					this.sidebarEl.style.bottom = this.initialBottom + diff + 'px';
				}
			} else {
				// user is draggin down
				this.sidebarEl.style.bottom = diff + 70 + 'px';
			}
		},
		touchEnd() {
			this.isDragging = false;
			this.sidebarEl.style.transition = 'all 0.3s ease';

			const endTime = new Date().getTime();
			const timeElapsed = endTime - this.startTime;
			const distanceY = this.startY - this.currentY;
			const threshold = 50; // Minimum distance for a swipe
			const timeThreshold = 300; // Maximum time for a swipe

			// Check if the swipe is fast and has a sufficient distance
			if (Math.abs(distanceY) > threshold && timeElapsed < timeThreshold) {
				if (distanceY > 0) { // Swipe up
					this.sidebarEl.style.bottom = '70px';
					eventBus.$emit('openSidebar');
				} else { // Swipe down
					this.sidebarEl.style.bottom = this.initialBottom + 'px';
					this.sidebarEl.style.height = '500px';
					eventBus.$emit('closeSidebar');
				}
			} else {
				if (parseInt(this.sidebarEl.style.bottom) > this.initialBottom / 2) {
					// The user dragged the sidebar upwards
					this.sidebarEl.style.bottom = '70px';
					eventBus.$emit('openSidebar');
				} else {
					// The user dragged the sidebar downwards
					this.sidebarEl.style.bottom = this.initialBottom + 'px';
					this.sidebarEl.style.height = '500px';
					eventBus.$emit('closeSidebar');
				}
			}
			
		},
		async createMap() {
			mapboxgl.accessToken = this.access_token;
			let p = 0.07
			const bounds = [
				[-149.958003 - p, -17.615597 - p],  // Southwest coordinates
				[-149.718904 + p, -17.447585 + p]   // Northeast coordinates
			];
			this.map = new mapboxgl.Map({
				container: "map",
				style: 'mapbox://styles/drewjgray3d/cl9de4f5y000c15s0c4sgches',
				center: this.center,
				zoom: 12,
				pitch: 60.54,
				bearing: 148.83,
				// maxBounds: bounds,
				attributionControl: false,
			});
			this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'bottom-right');
			this.map.addControl(new mapboxgl.NavigationControl({visualizePitch: true}), 'bottom-right');
			this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
			this.map.addControl(new mapboxgl.ScaleControl());

			// this.map.on('mousemove', (e) => {
			// 	const { lng, lat } = e.lngLat;
			// 	console.log(`Longitude: ${lng}, Latitude: ${lat}`);
			// });


			this.map.on('load', () => {
				this.loadMapData();
				this.map.on('style.load', () => {
					this.loadRestorationData();
					this.loadSatellite();
					this.loadDrone();
					this.resetLayers();
				});

			});
		// End createMap ---------------------------------
		},
		loadMapData() {
			this.loadRestorationData();
			this.loadBuoys();
			this.loadCameras();
			this.loadOctopus();
			this.loadSatellite();
			this.loadDrone();


		},
		resetLayers() {
			eventBus.$emit('resetLayers');
		},
		async loadRestorationData() {
			this.branches = await this.getBranches();
			this.selectedBranch = this.branchOptions.find(branch => branch.id === 'moorea');
			this.outplantSites = [];
			this.branches.forEach(branch => {
				this.addBranch(branch)
				branch.nurseries.forEach(nursery => {
					nursery.branch = branch.branch.id; // add branch if for reference
					this.addNursery(nursery)
			});
				this.outplantSites = this.outplantSites.concat(branch.outplantSites);
			})
			this.addOutplantSites(this.outplantSites);

		},
		addBranch(branch) {
			const popup = new mapboxgl.Popup({ offset: [0, -20] })
				.setHTML(
					`<div id=${branch.branch.id} class="popup-branch">
						<div class="clearfix">
							<div style="float: left; width: 33%;">
								<div class="popup-img-branch"></div>
							</div>
							<div style="float: left; width: 67%; text-align: left;">
								<div class="popup-title">
									${branch.branch.name}
								</div>
								<div class="popup-subtitle-nursery">
									BRANCH
								</div>
							</div>
						</div>
						<div style="clear: both;"></div>
						<div>
							<div style="border-top: 0.5px solid rgba(255, 255, 255, 0.33);"></div>
						</div>
						<div class="popup-wrapper">
							<div class="level is-mobile" style="margin: 0px;">
								<div class="level-left">
									<div class="level-item">
										Nurseries
									</div>
								</div>
								<div class="level-right">
									<div class="level-item popup-unit">
										${branch.nurseries.length}
									</div>
								</div>
							</div>
							<div class="level is-mobile" style="margin: 0px;">
								<div class="level-left">
									<div class="level-item">
										Outplant Sites
									</div>
								</div>
								<div class="level-right">
									<div class="level-item popup-unit">
										${branch.outplantSites.length}
									</div>
								</div>
							</div>
						</div>
						<button id="${branch.branch.id}-button" class="popup-button" >
							Open Details 
						</button >
					</div>`
				)
				.on('open', (e) => {
					this.selectedBranch = this.branchOptions.find(branch => branch.id === e.target._content.childNodes[0].id);
					this.popupButton = document.getElementById(`${e.target._content.childNodes[0].id}-button`);
					this.popupButton.addEventListener('click', (event) => {
						eventBus.$emit('openSidebar', 'branches')
						this.$router.push({ path: 'branches' });
					});
				})
				.on('close', () => {
					if (this.popupButton) {
						this.popupButton.removeEventListener('click', () => { });
						this.popupButton = null;
					}
				})
			const el = document.createElement('div');
			el.className = 'marker-branch';
			const marker = new mapboxgl.Marker(el)
				.setLngLat([branch.branch.geoLocation.longitude, branch.branch.geoLocation.latitude])
				.setPopup(popup)
				.addTo(this.map)
			marker._element.style.visibility = 'visible'
			marker._element.style.zIndex = 3;
			this.branch_markers.push(marker);
		},
		addNursery(nursery) {
			this.nurseries.push(nursery)
			const popup = new mapboxgl.Popup({ offset: [0, -20] })
				.setHTML(
					`<div id=${nursery.id} class="popup-nursery">
						<div class="clearfix">
							<div style="float: left; width: 33%;">
								<div class="popup-img-nursery"></div>
							</div>
							<div style="float: left; width: 67%;">
								<div class="popup-title">
									${nursery.name}
								</div>
								<div class="popup-subtitle-nursery">
									CORAL NURSERY
								</div>
							</div>
						</div>
						<div style="clear: both;"></div>
						<div>
							<div style="border-top: 0.5px solid rgba(255, 255, 255, 0.33);"></div>
							<div class="popup-wrapper">
								<div class="level is-mobile" style="margin: 0px;">
									<div class="level-left">
										<div class="level-item">
											Creation Date
										</div>
									</div>
									<div class="level-right">
										<div class="level-item popup-unit">
											${new Date(nursery.created.toDate()).toLocaleString('en-US', { month: 'long', year: 'numeric' })}
										</div>
									</div>
								</div>
								<div class="level is-mobile" style="margin: 0px;">
									<div class="level-left">
										<div class="level-item">
											Corals Growing
										</div>
									</div>
									<div class="level-right">
										<div class="level-item popup-unit">
											${nursery.fragmentCount}
										</div>
									</div>
								</div>
							</div>
						</div>
						<button id="${nursery.id}-button" class="popup-button" >
							Open Details 
						</button >
					</div>`
				)
				.on('open', (e) => {
					this.selectedNursery = this.nurseries.filter((nursery) => {
						return nursery.id == e.target._content.childNodes[0].id
					})[0];
					this.popupButton = document.getElementById(`${e.target._content.childNodes[0].id}-button`);
					this.popupButton.addEventListener('click', (event) => {
						eventBus.$emit('openSidebar', 'nurseries')
						this.$router.push({ path: 'nurseries' });
					});
				})
				.on('close', () => {
					if (this.popupButton) {
						this.popupButton.removeEventListener('click', () => { });
						this.popupButton = null;
					}
				})
			const el = document.createElement('div');
			el.className = 'marker-nursery';
			const marker = new mapboxgl.Marker(el)
				.setLngLat([nursery.geoLocation.longitude, nursery.geoLocation.latitude])
				.setPopup(popup)
				.addTo(this.map)
			marker._element.style.visibility = 'visible'
			marker._element.style.zIndex = 2;
			this.nursery_markers.push(marker);
		},
		addOutplantSites(sites) {
			const geoJSONData = this.createGeoJSONForOutplantSites(sites);

			if (!this.map.getSource('outplantSites')) {
				this.map.addSource('outplantSites', {
					type: 'geojson',
					data: geoJSONData
				});
			} else {
				this.map.getSource('outplantSites').setData(geoJSONData);
			}

			if (!this.map.getLayer('outplantSite-layer')) {
				this.map.addLayer({
					id: 'outplantSite-layer',
					type: 'fill-extrusion',
					source: 'outplantSites',
					layout: {
						'visibility': 'visible'
					},
					paint: {
						'fill-extrusion-color': [
							'case',
							['boolean', ['feature-state', 'hover'], false],
							'#00A5FC', // Hover
							'#27BDF4'  // Default 
						],
						'fill-extrusion-height': 25,
						'fill-extrusion-opacity': 0.7,
					}
				});
			}

			this.map.on('mouseenter', 'outplantSite-layer', () => {
				this.map.getCanvas().style.cursor = 'pointer';
			});

			this.map.on('mouseleave', 'outplantSite-layer', () => {
				this.map.getCanvas().style.cursor = '';
			});

			this.map.on('click', (event) => {
				const features = this.map.queryRenderedFeatures(event.point, {
					layers: ['outplantSite-layer'],
				});

				if (!features.length) {
					return
				}

				const name = features[0].properties.name;
				this.selectedOutplantSite = this.outplantSites.find(site => site.name === name);

				// If marker is nearby suppress reef popup
				let markerNearby = false
				let pixelRadius = 30;

				this.allMarkers.forEach(marker => {
					let markerPixel = this.map.project(marker._lngLat);
					let pixelDistance = Math.sqrt(Math.pow(event.point.x - markerPixel.x, 2) + Math.pow(event.point.y - markerPixel.y, 2));
					if (pixelDistance < pixelRadius) markerNearby = true;
				});

				if (!markerNearby) {
					new mapboxgl.Popup({ offset: [0, -20] })
						.setLngLat([event.lngLat.lng, event.lngLat.lat])
						.setHTML(
							`<div id=${this.selectedOutplantSite.name} class="popup-reef">
									<div class="clearfix">
										<div style="float: left; width: 33%;">
											<div class="popup-img-reef"></div>
										</div>
										<div style="float: left; width: 67%;">
											<div class="popup-title">
												${this.selectedOutplantSite.name}
											</div>
											<div class="popup-subtitle-reef">
												OUTPLANT SITE
											</div>
										</div>
									</div>
									<div style="clear: both;"></div>
									<div>
										<div style="border-top: 0.5px solid rgba(255, 255, 255, 0.33);"></div>
										<div class="popup-wrapper">
											<div class="level is-mobile" style="margin: 0px;">
												<div class="level-left">
													<div class="level-item">
														Outplanting Date
													</div>
												</div>
												<div class="level-right">
													<div class="level-item popup-unit">
														${new Date(this.selectedOutplantSite.created.toDate()).toLocaleString('en-US', { month: 'long', year: 'numeric' })}
													</div>
												</div>
											</div>
											
										</div>
									</div>
									<button id="${this.selectedOutplantSite.id}" class="popup-button" >
										Open Details 
									</button >
								</div>`
						).on('open', (e) => {
							this.popupButton = document.getElementById(`${this.selectedOutplantSite.id}`);
							this.popupButton.addEventListener('click', () => {
								eventBus.$emit('openSidebar', 'outplantSites')
								this.$router.push({ path: 'outplantSites' });
							});
						}).on('close', () => {
							if (this.popupButton) {
								this.popupButton.removeEventListener('click', () => { });
								this.popupButton = null;
							}
						})
						.addTo(this.map);
				}
			});
		},
		createGeoJSONForOutplantSites(outplantSites) {
			const features = outplantSites
				.filter(site => site.perimeter && site.perimeter.length > 0)
				.map(site => {
					const coordinates = site.perimeter.map(point => [point.longitude, point.latitude]);
					return {
						type: 'Feature',
						properties: {
							name: site.name || 'Outplant Site',
							created: new Date(site.created.toDate()).toLocaleString('en-US', { month: 'long', year: 'numeric' }),
							stats: site.stats,
						},
						geometry: {
							type: 'Polygon',
							coordinates: [coordinates],
						}
					};
				});

			return {
				type: 'FeatureCollection',
				features: features
			};
		},
		async getBranches() {
			const branches = [];
			const orgDocId = 'coral-gardeners';

			// Retrieve all branches under the 'coral-gardeners' organization
			const branchesSnapshot = await getDocs(collection(db_RestorationApp, `Orgs/${orgDocId}/Branches`));
			for (const branchDoc of branchesSnapshot.docs) {
				if (branchDoc.id.startsWith('sandbox')) continue;
				const branchData = branchDoc.data();
				const branchId = branchDoc.id;

				// Retrieve all nurseries under the current branch
				const nurseriesCollectionRef = collection(db_RestorationApp, `Orgs/${orgDocId}/Branches/${branchId}/Nurseries`);
				const nurseriesSnapshot = await getDocs(nurseriesCollectionRef);
				const nurseries = nurseriesSnapshot.docs.map(nurseryDoc => ({
					id: nurseryDoc.id,
					...nurseryDoc.data()
				}));

				// Retrieve all outplant sites under the current branch
				const outplantSitesCollectionRef = collection(db_RestorationApp, `Orgs/${orgDocId}/Branches/${branchId}/OutplantSites`);
				const outplantSitesSnapshot = await getDocs(outplantSitesCollectionRef);
				const outplantSites = outplantSitesSnapshot.docs.map(siteDoc => ({
					id: siteDoc.id,
					...siteDoc.data()
				}));

				// Add the branch with its nurseries and outplant sites to the result
				branches.push({
					branch: { id: branchId, ...branchData },
					nurseries: nurseries,
					outplantSites: outplantSites,
				});
			}
			return branches;
		},
		loadBuoys() {
			let ct = new Date();
			ct.setDate(ct.getDate() - 7);
			let start = ct.toISOString();
			let end = new Date().toISOString();
			let weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

			this.spotterIDs.forEach(spotterID => {
				// Get smart mooring data -------------------------------
				fetch(`https://api.sofarocean.com/api/sensor-data?spotterId=${spotterID}&startDate=${start}&endDate=${end}&token=${this.token}`)
				.then(response => response.json()).then(response => {
					let buoyTemp = {
							'Day': {
								'bottomTemp': [],
								'timestamp': [],
								'label': [],
								'bleaching': [],
							},
							'Week': {
								'bottomTemp': [],
								'timestamp': [],
								'label': [],
								'bleaching': [],
							},
							'Month': {
								'bottomTemp': [],
								'timestamp': [],
								'label': [],
								'bleaching': [],
							},
							'Year': {
								'bottomTemp': [],
								'timestamp': [],
								'label': [],
								'bleaching': [],
							},
						}
					let top_temp = '';
					let bottom_temp = '';
					let timestamp = '';
					response.data.forEach(data => {
						if (data.sensorPosition == 1) {
							buoyTemp['Week'].bottomTemp.push(data.value.toFixed(2));
							let date = new Date(data.timestamp).getDay();
							buoyTemp['Week'].label.push(weekdays[date]);
							buoyTemp['Week'].timestamp.push(data.timestamp);
							buoyTemp['Week'].bleaching.push(this.bleaching_threshold);
							top_temp = data.value.toFixed(1);
							timestamp = data.timestamp;
						} if (data.sensorPosition == 2) {
							bottom_temp = data.value.toFixed(1)
						}
					});
					// Get wind/wave data -------------------------------
					fetch(`https://api.sofarocean.com/api/latest-data?spotterId=${spotterID}&includeWindData=true&token=${this.token}`)
					.then(response => response.json()).then(weather => {
						this.sensors.push({
							'id': spotterID,
							'type': 'buoy',
							'temp': buoyTemp,
							'wind': weather.data.wind,
							'waves': weather.data.waves,
							'top_temp': top_temp,
							'bottom_temp': bottom_temp,
							'timestamp': timestamp,
							'depth': this.spotterDepths[spotterID],
						});

						let current_value = response.data[response.data.length - 1];
						let temp = current_value.value.toFixed(1)
						let date = new Date(current_value.timestamp).toString().split(' ').slice(4, 5).join(' ');
						let depth = this.spotterDepths[spotterID];
						const popup = new mapboxgl.Popup({ offset: [0, -20] })
							.setHTML(
								`<div id=${spotterID} class="popup-buoy">
									<div class="clearfix">
										<div style="float: left; width: 33%;">
											<div class="popup-img-buoy"></div>
										</div>
										<div style="float: left; width: 67%;">
											<div class="popup-title">
												${spotterID}
											</div>
											<div class="popup-subtitle-buoy">
												BUOY
											</div>
										</div>
									</div>
									<div style="clear: both;"></div>
									<div>
										<div style="border-top: 0.5px solid rgba(255, 255, 255, 0.33);"></div>
										<div class="popup-wrapper">
											<div class="level is-mobile" style="margin: 0px;">
												<div class="level-left">
													<div class="level-item">
														Latest Data
													</div>
												</div>
												<div class="level-right">
													<div class="level-item popup-unit">
														${date}
													</div>
												</div>
											</div>
											<div class="level is-mobile" style="margin: 0px;">
												<div class="level-left">
													<div class="level-item">
														Depth
													</div>
												</div>
												<div class="level-right">
													<div class="level-item popup-unit">
														<div>${depth} m</div>
													</div>
												</div>
											</div>
											<div class="level is-mobile" style="margin: 0px;">
												<div class="level-left">
													<div class="level-item">
														Temperature
													</div>
												</div>
												<div class="level-right">
													<div class="level-item popup-unit">
														${temp} °C
													</div>
												</div>
											</div>
											
										</div>
									</div>
									<button id=${spotterID} class="popup-button" >
										Open Details 
									</button >
								</div>`
							)
							.on('open', (e) => {
								this.selectedSensor = this.sensors.filter((sensor) => {
									return sensor.id == e.target._content.childNodes[0].id
								})[0];
								this.popupButton = document.getElementById(`${e.target._content.childNodes[0].id}`);
								this.popupButton.addEventListener('click', (event) => {
									eventBus.$emit('openSidebar', 'sensors')
									this.$router.push({ path: 'sensors' });
								});
							})
							.on('close', () => {
								if (this.popupButton) {
									this.popupButton.removeEventListener('click', () => { });
									this.popupButton = null;
								}
							})
						const el = document.createElement('div');
						el.className = 'marker-buoy';
						const marker = new mapboxgl.Marker(el)
							.setLngLat([current_value.longitude, current_value.latitude])
							.setPopup(popup)
							.addTo(this.map)
						marker._element.style.visibility = 'visible'
						this.sensor_markers.push(marker);
					})
				});
			});
		}, // end buoys
		async loadCameras() {	
			const querySnapshot = await getDocs(collection(db, "Cameras"));
			querySnapshot.forEach((doc) => {
				if (!doc.id.startsWith('debug')) {
					let camera = doc.data();
					let date = new Date(camera.zed_time.toDate()).toString().split(' ').slice(1, 5).join(' ');
					const popup = new mapboxgl.Popup({ offset: [0, -20], anchor: null })
						.setHTML(
							`<div id=${camera.id} class="popup-buoy">
								<div class="clearfix">
									<div style="float: left; width: 33%;">
										<div class="popup-img-camera"></div>
									</div>
									<div style="float: left; width: 67%;">
										<div class="popup-title">
											${camera.id}
										</div>
										<div class="popup-subtitle-camera">
											CAMERA
										</div>
									</div>
								</div>
								<div style="clear: both;"></div>
								<div>
									<div style="border-top: 0.5px solid rgba(255, 255, 255, 0.33);"></div>
									<div class="popup-wrapper">
										<div class="level is-mobile" style="margin: 0px;">
											<div class="level-left">
												<div class="level-item">
													Latest Data
												</div>
											</div>
											<div class="level-right">
												<div class="level-item popup-unit">
													${date}
												</div>
											</div>
										</div>
										<div class="level is-mobile" style="margin: 0px;">
											<div class="level-left">
												<div class="level-item">
													Depth
												</div>
											</div>
											<div class="level-right">
												<div class="level-item popup-unit">
													${camera.depth} m
												</div>
											</div>
										</div>
									</div>
								</div>
								<button id=${camera.id} class="popup-button" >
									Open Details 
								</button >
							</div>`
						)
						.on('open', (e) => {
							this.selectedSensor = this.sensors.filter((sensor) => {
								return sensor.id == e.target._content.childNodes[0].id
							})[0];
							this.popupButton = document.getElementById(`${camera.id}`);
							this.popupButton.addEventListener('click', (event) => {
								eventBus.$emit('openSidebar', 'sensors')
								this.$router.push({ path: 'sensors' });
							});
						}).on('close', () => {
							if (this.popupButton) {
								this.popupButton.removeEventListener('click', () => { });
								this.popupButton = null;
							}
						});
					const el = document.createElement('div');
					el.className = 'marker-camera';
					const marker = new mapboxgl.Marker(el)
						.setLngLat([camera.location._long, camera.location._lat])
						.setPopup(popup)
						.addTo(this.map)
					marker._element.style.visibility = 'visible'
					marker._element.style.zIndex = 4;
					this.sensor_markers.push(marker);
					this.sensors.push(camera);
				}
			});
		}, // end cameras
		async loadOctopus() {
			const querySnapshot = await getDocs(collection(db, "Live"));
			querySnapshot.forEach((doc) => {
				if (!doc.id.startsWith('debug')) {
					let camera = doc.data();
					const popup = new mapboxgl.Popup({ offset: [0, -20], anchor: null })
						.setHTML(
							`<div id=${camera.id} class="popup-livestream">
								<div class="clearfix">
									<div style="float: left; width: 25%;">
										<div class="popup-img-octopus"></div>
									</div>
									<div style="float: left; width: 75%;">
										<div class="popup-title">
											${camera.name}
										</div>
										<div class="popup-subtitle-camera">
											LIVE STREAM
										</div>
									</div>
								</div>
								<div style="clear: both"></div>
								<div style="margin-top: 5px; position: relative; width: 100%; height: 0; padding-bottom: 56.25%">
									<iframe 
										style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
										src="https://camstreamer.com/embed/0goMhglohTjDT3ciyjIQkX0JpBkx4DGDOJ7Wadc1?modestbranding=1&controls=0&disablekb=1&rel=0" 
										frameborder="0" 
										allowfullscreen>
									</iframe>
								</div>
								<div style="text-align: center">
									<a href="https://camstreamer.com/redirect/0goMhglohTjDT3ciyjIQkX0JpBkx4DGDOJ7Wadc1" target="_blank">
										<button id=${camera.id} class="popup-button" style="width: 100%">
											<i class="mdi mdi-youtube"></i> Open on YouTube 
										</button>
									</a>
								</div>
							</div>`
						)
					const el = document.createElement('div');
					el.className = 'marker-camera';
					const marker = new mapboxgl.Marker(el)
						.setLngLat([camera.location._long, camera.location._lat])
						.setPopup(popup)
						.addTo(this.map)
					this.ocotpusMarker.push(marker)
				}
			});
		},
		loadSatellite() {
			this.map.addSource('satellite', {
				type: 'raster',
				url: 'mapbox://drewjgray3d.06ea77m0'
			});
			this.map.addLayer({
				'id': 'satellite-layer',
				'type': 'raster',
				'source': 'satellite',
				'layout': {
					'visibility': 'none'
				},
			});
		},
		loadDrone() {
			this.droneLayers.forEach(layer => {
				this.map.addSource(`drone-${layer}`, {
					type: 'raster',
					url: `mapbox://drewjgray3d.${layer}`
				});
				this.map.addLayer({
					'id': `drone-${layer}`,
					'type': 'raster',
					'source': `drone-${layer}`,
					'layout': {
						'visibility': 'none'
					},
				});
			});
		},
		handleResize() {
			if (this.map) {
				this.map.resize();
			}
		}
	},
}
</script>

<style scoped>
</style>
