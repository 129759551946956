<template>
    <div class="close-wrapper close-button">
        <div class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <div>
                        <b-button class="close-btn" @click="closeSidebar()" icon-left="close" size="is-small"></b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus  } from '@/events.js'

export default {
    name: 'Close',
    methods: {
        closeSidebar() {
            eventBus.$emit('closeSidebar');
        }
    }
 
}
</script>

<style scoped>
.close-wrapper {
    margin: 10px;
    margin-top: 20px;
}
.close-btn {
    background-color: #1F1F1F;
    color: white;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 50%;
    border: none;
    margin-right: 20px;
    transition: .25s;
    animation-timing-function: ease-in-out;
}

.close-btn:hover {
    color: var(--primary);
    scale: 1.1;
}

.button.is-small:not(.is-rounded) {
    border-radius: 50%;
}
</style>